import {useTranslation} from "react-i18next";
import React from "react";

function RecordCount({...props}) {
    const {t} = useTranslation();
    const {totalRecords, state, page} = props;
    let recordsInfoText = "";
    const {pageSize, pageIndex} = state;

    if (page && page.length > 0) {
        let recordsCountFrom = pageIndex * pageSize + 1;
        let recordsCountTo = recordsCountFrom + page.length - 1;
        recordsInfoText = t('table.totalRecords.label',
            {recordsCountFrom: recordsCountFrom, recordsCountTo: recordsCountTo, totalRecords: totalRecords});
    } else {
        recordsInfoText = "No records.";
    }

    return (
        <tr>
            <td colSpan="10000">
                {recordsInfoText}
            </td>
        </tr>
    );
};

export default RecordCount;
