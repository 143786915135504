import React, {useState, useEffect, useRef} from 'react';
import {Modal} from 'react-bootstrap'
//import AdjSchedForm from './AdjSchedForm'
//import AdjustmentsTable from './AdjustmentsTable'
import {useTranslation} from 'react-i18next';
//import {parse, stringify, toJSON, fromJSON} from 'flatted';
import moment from 'moment';
import {getAdjustmentSchedule} from 'utils/adjustment-data';
import {useAuth} from "../../use-auth";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import TableScrollbar from 'react-table-scrollbar';

// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Form,
  Table
} from "react-bootstrap";




const AdjSchedViewModal = ({adjustmentScheduleData,
  handleClose,
  show,
  setDataUpdatedCnt,
  dataUpdatedCnt,
  adjSch,
  scheduleDetails,
  setAdjSch,
  setScheduleDetails,
  notify,
  notificationAlertRef}) => {

  const {t} = useTranslation();
  const auth = useAuth();
  
  //const [modal, setModal]   = useState(false);
  const [loading, setLoading] = useState(false);
  //const notificationAlertRef  = useRef(null);
  
  const [styleCode, setStyleCode] = useState({visibility: "hidden"});
  const [styleDesc, setStyleDesc] = useState({visibility: "hidden"});
  
  // const notify = (type, place, message) => {
  //   var options = {};
  //   options = {
  //     place: place,
  //     message: (
  //       <div>
  //         <div>
  //           {message}
  //         </div>
  //       </div>
  //     ),
  //     type: type,
  //     icon: "nc-icon nc-bell-55",
  //     autoDismiss: 7,
  //   };
  //   notificationAlertRef.current.notificationAlert(options);
  // };
  
  const [ selectedAdjustment, setSelectedAdjustment] = useState({});
  // const [ adjSch, setAdjSch] = useState(null);
  // const [ scheduleDetails, setScheduleDetails] = useState([]);

  //debugger;


  // useEffect( () => {

  //   console.log("useEffect called.  ID is " + props.adjustmentScheduleData.id[0]);

  //   if (props.adjustmentScheduleData.id[0]){
  //     getAdjustmentSchedule(auth.getSelectedOrgEntity(),
  //                           props.adjustmentScheduleData.id[0],
  //                           (adjustmentSchedule, scheduleDetails) => {
  //                             console.log("Dat is : " + JSON.stringify(adjustmentSchedule));
  //                             console.log("Dat is : " + JSON.stringify(scheduleDetails));
  //                               setAdjSch(adjustmentSchedule);
  //                               setScheduleDetails(scheduleDetails._embedded.adjustmentScheduleDetails);
  //                               setLoading(false);
  //                           },
  //                           () => {
  //                               console.log('getAdjustmentSchedule() returned error.');
  //                               setAdjSch(null);
  //                               setLoading(false);
  //                           });
  //     }}, 
  //     [props.adjustmentScheduleData.id[0]]);



      const [codeState, setCodeState] = useState(true);
      const [descState, setDescState] = useState(true);
      const [startDate, setStartDate] = useState(true);
      const [endDate, setEndDate] = useState(true);
      const [styleEndDate, setStyleEndDate] = useState({});
      const [styleStartDate, setStyleStartDate] = useState({});
      const [adjustmentList, setAdjustmentList] = useState(null);


      const validationData = (value) => { return ( value && value.length > 0); }

      const validationDate = (value) => {
        //console.log(d.isAfter(yesterday))
    
        /* if(!d.isAfter(yesterday))
          return false; */
        let d = moment(value);
        const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/\d{4}$/ ;
        if(moment.isMoment(value)){
          //console.log("hola");
          return regex.test(value.format(t('date.short_format')));
        }
        else if(moment(value) !== null){
          //console.log("Entro   =>   ")
          //console.log(d.format("MM-DD-YYYY"));
          return regex.test(d.format(t('date.short_format')))
        }
        return regex.test(d);
    }
  

  // const handleFormChange = (event, param) => {

  //   let eventClassName = event.constructor.name;
  //   let eventTargetName = '';
  //   let eventTargetVal = '';

  //   console.log("Event object keys: " + Object.keys(event)); 
  //   console.log("Event object class: " + event.constructor.name); 

  //   //Set the correct eventTargetName according to the event type passed.
  //   // eventTargetName will be used in the switch to update the correct value.

  //   if (eventClassName === 'SyntheticBaseEvent') {

  //     if (!event.target) {
  //       notify("danger", "tc", `handleFormChange() : No target attribute was received in event.`);
  //     }

  //     if ( (!event.target.name) && (!event.currentTarget.name) ) {
  //       notify("danger", "tc", `handleFormChange() : No name attribute was received in event.`);
  //     }

  //     eventTargetName = (event.target.name) ? event.target.name : event.currentTarget.name;
  //     eventTargetVal = (event.target.value) ? event.target.value : event.currentTarget.value;

  //   }
  //   else if (eventClassName === 'Moment') {
  //       eventTargetName = param;
  //   }


  //   console.log ('>>> handleFormChange(): target name is : ' + eventTargetName ) ; 
  //   console.log ('>>> handleFormChange(): target value is : ' + eventTargetVal ) ; 
  //   console.log ('>>> handleFormChange(): param: ' + param ); 



  //   let adjustmentsTmp = []
    
  //   switch ( eventTargetName ) {
  //     case 'adjustmentSchedule.code' : 
  //       setAdjustmentSchedule({...adjustmentSchedule, 
  //                                  code: event.target.value, 
  //                                  isDirty : true
  //                              });
  //     break;

  //     case 'adjustmentSchedule.description' :
  //       setAdjustmentSchedule({...adjustmentSchedule, 
  //                                 description: event.target.value,
  //                                 isDirty : true
  //                             });
  //       break;
  //     case 'adjustmentSchedule.startDate':
  //       setAdjustmentSchedule({...adjustmentSchedule, 
  //         startDate: event, // onChange() for <DateTime> sends a moment() object as the event.
  //         isDirty : true
  //     });
  //     break;
  //     case 'adjustmentSchedule.endDate':
  //       setAdjustmentSchedule({...adjustmentSchedule, 
  //         endDate: event, // onChange() for <DateTime> sends a moment() object as the event.
  //         isDirty : true
  //     });
  //     break;
  //     case 'btnAddAdjustment' : 
  //       //let adjustment = adjustmentService.getAdjustmentByCode (event.target.value)
  //       adjustmentsTmp = []
  //       if (adjustmentSchedule.adjustments) {
  //         adjustmentsTmp = adjustmentSchedule.adjustments;
  //       }
  //       if (adjustmentsTmp.some( (adj) => adj.code === selectedAdjustment.value ) ) {
  //         //alert()
  //         notify("primary", "tc", `Adjustment ${selectedAdjustment.value} is already in the schedule.`);
  //       }
  //       else {
  //       adjustmentsTmp.push({ id : selectedAdjustment.key,
  //                      code: selectedAdjustment.value,
  //                      description : selectedAdjustment.label,
  //                      freeGoodsFlag : true,
  //                      bundleFlag : true });
  //       setAdjustmentSchedule(
  //         {
  //             ...adjustmentSchedule, 
  //             adjustments: adjustmentsTmp,
  //             isDirty : true
  //         }
  //       );
  //       }
  //       break;
  //       case 'adjustmentDelete' :
  //         adjustmentsTmp = []
  //         adjustmentsTmp = adjustmentSchedule.adjustments.filter( (adj) => { 
  //           return (adj.code !== eventTargetVal);
  //         } );
  //         setAdjustmentSchedule({...adjustmentSchedule, 
  //                                   adjustments: adjustmentsTmp,
  //                                   isDirty : true
  //                               });
  //         break;
  //         default: {}
  //   }

  // }

//   const handleFormOrder = (event, param, order) => {
//     //console.log(event.target.name + " " + event.target.value);
//     //let adjSch = adjustmentSchedule;
//     let beforeIndex = 0;
//     let afterIndex = 0;
//     let currentIndex = 0;
//     let tempAdjSch = {};

//     if(adjSch.adjustments.length === 1)
//       return;

//     for(let i = 0; i < adjSch.adjustments.length; i++){
//       if(adjSch.adjustments[i].code === param){
//         if(i === 0){
//           afterIndex = i+1;
//         }
//         else if(i+1 === adjSch.adjustments.length){
//           afterIndex = i;
//           currentIndex = i;
//           beforeIndex = i-1;
//         }
//         else{
//           afterIndex = i+1;
//           currentIndex = i;
//           beforeIndex = i-1;
//         }
//         break;
//       }
//     }
//     //console.log(beforeIndex + " " + currentIndex + " " + afterIndex);

//     if(order === "up"){
//       if(beforeIndex !== currentIndex){
//         tempAdjSch = adjSch.adjustments[currentIndex];
//         adjSch.adjustments[currentIndex] = adjSch.adjustments[beforeIndex];
//         adjSch.adjustments[beforeIndex] = tempAdjSch;
//       }
//     }
//     else if(order === "down"){
//       if(afterIndex !== currentIndex){
//         tempAdjSch = adjSch.adjustments[currentIndex];
//         adjSch.adjustments[currentIndex] = adjSch.adjustments[afterIndex];
//         adjSch.adjustments[afterIndex] = tempAdjSch;
//       }
//     }

//     setAdjustmentSchedule({...adjustmentSchedule, 
//       adjustments: adjSch.adjustments,
//       isDirty : true
//     });
// }

  const handleFormSubmit = (event, param) => {

    if (!adjSch.adjustments || (adjSch.adjustments.length === 0)) { 
      notify ("warning", "tc", ` Please add at least one adjustment before saving.`);
    }
    // else {

    //   saveAdjustmentSchedule( auth.getSelectedOrgEntity(), adjustmentSchedule, 
    //     (savedSchedule) => {
    //       setAdjustmentSchedule({...savedSchedule, isDirty: false});
    //       props.setDataUpdatedCnt(props.dataUpdatedCnt + 1);
    //       setLoading(false);
    //       notify("primary", "tc", ` Adjustment Schedule ${adjustmentSchedule.code} created successfully`);
    //       resetForm(event, param);
    //       props.handleClose();
           
    //     },
    //   () => {
    //       setLoading(false);
    //       notify ("primary", "tc", ` Could not save Adjustment Schedule ${adjustmentSchedule.code}.`);
    //   }); 

    // }

  }

  const handleDates = (event, param, passed) =>{
    // console.log(event);
    // console.log(param);
    // console.log(passed);
    if(moment.isMoment(event)){
      const d = event;
      switch(param){
        case 'startDate':
          setAdjSch({...adjSch, 
            startDate: d.format(t('date.short_format')),
            isDirty : true
          });
        break;
        case 'endDate':
          setAdjSch({...adjSch, 
            endDate: d.format(t('date.short_format')),
            isDirty : true
        });
        break;
        default: {}
      }
    }
    else{
      const regexDigitsSlash = /^[\d/]{0,10}$/;
      let value = event.replace(/ /g, '');

      if(!regexDigitsSlash.test(value)){
        return;
      }

      if (value.match(/^\d{2}$/) !== null) {
        value = value + '/';
      } 
      else if (value.match(/^\d{2}\/\d{2}$/) !== null) {
        value = value + '/';
      }

      let splitDate = value.split('/');
      if(splitDate[0].length < 2 && value.length === 2){
        //console.log(splitDate)
        splitDate[0] = "0" + splitDate[0] + "/";
        value = splitDate[0];
      }
      else if(value.length === 5 && splitDate[1].length < 2){
        //console.log(splitDate);
        splitDate[0] = splitDate[0] + "/";
        splitDate[1] = "0" + splitDate[1] + "/";
        value = splitDate[0] + splitDate[1];
      }
      
      switch(param){
        case 'startDate':
          setAdjSch({...adjSch, 
            startDate: value,
            isDirty : true
          });
        break;
        case 'endDate':
          setAdjSch({...adjSch, 
            endDate: value,
            isDirty : true
        });
        break;
        default: {}
      }
    }
  }

  const resetForm = (event, param) => {

    setAdjSch(null);
    setScheduleDetails(null);
    document.getElementById("adj-sched-view-form").reset(); 
  }

  if (adjSch && scheduleDetails) {
  
    return (
 
    <>
    <div className="rna-container">
  </div>
      <Modal show={show} onHide={(event)=> {resetForm(); handleClose(event);}} size="xl" animation={false}>
        <Modal.Header>
          <Modal.Title>Adjustment Schedule Details</Modal.Title>
          <button className="btn-close" onClick={ (event)=> {
              resetForm(); 
              handleClose(event);
        }}
        ></button>
        </Modal.Header>
        <Modal.Body>
        <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4"></Card.Title>
              </Card.Header>
              <Card.Body>
                <Form action="#" method="#" id='adj-sched-view-form'>
                  <Row>
                    <Col md={2}>
                      <Form.Group>
                        <div className="alert messageBox mt-1" style={styleCode}>{adjSch.code}</div>
                        <label htmlFor='adjustmentSchedule.code'>{t('adjustmentSchedules.code.label')}</label>
                        <Form.Control
                          disabled
                          name="adjustmentSchedule.code"
                          placeholder="Enter code"
                          type="text"
                          maxLength="50"
                          //isInvalid={!codeState}
                          value={adjSch.code}
                          // onInput={ (e) => { 
                          //     props.onFormChange(e);
                          //     if (validationData(e.target.value)) {
                          //       setCodeState(true);
                          //     } 
                          //     else {
                          //       setCodeState(false);
                          //     } 
                          //   }
                          // }
                          onMouseOver={(e) => {
                            setStyleCode({visibility: "visible"});
                          }}
                          onMouseOut={(e) => {
                            setStyleCode({visibility: "hidden"});
                          }}
                        />
                        {/* {codeState ? null : (<label className="error text-danger">This field is required.</label>)} */}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <div className="alert messageBox mt-1" style={styleDesc}>{adjSch.description}</div>
                        <label htmlFor='adjustmentSchedule.description'>{t('adjustmentSchedules.description.label')}</label>
                        <Form.Control
                          disabled
                          name="adjustmentSchedule.description"
                          placeholder="Enter Description"
                          type="text"
                          maxLength="200"
                          value={adjSch.description}
                          onMouseOver={(e) => {
                            setStyleDesc({visibility: "visible"});
                          }}
                          onMouseOut={(e) => {
                            setStyleDesc({visibility: "hidden"});
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Group>
                        <label>{t('adjustmentSchedules.effectiveDate.label', {count: 10})}</label>
                        <Form.Control
                          disabled
                          name="adjustmentSchedule.startDate"
                          type="text"
                        //  value={adjSch.startDate}/>
                        value={ moment.utc(adjSch.startDate).format(t('date.short_format')) }/>
                      </Form.Group>
                    </Col>

                    <Col md={2}>
                    <Form.Group>
                        <label>{t('adjustmentSchedules.expireDate.label', {count: 10})}</label>
                        <Form.Control
                          disabled
                          name="adjustmentSchedule.endDate"
                          type="text"
                          // value={adjSch.endDate}/>
                          value={moment.utc(adjSch.endDate).format(t('date.short_format'))}/>
                      </Form.Group>
                    </Col>                  

                  </Row>
                  {/* <Row>
                    <Col md="6">
                      <Form.Group>  
                        <label>Adjustments</label>                    
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="selectedAdjustment"
                          value={props.selectedAdjustment}
                          onChange={props.onAdjustmentSelect}
                          placeholder="Select adjustment"
                          options={ adjustmentList }
                        />
                      </Form.Group>
                    </Col>
                    <Col md="2" className="middle-btn">
                      <Form.Group>
                           <Button
                            className="btn-round btn-sm"
                            name="btnAddAdjustment"
                            onClick={props.onFormChange}
                            // disabled={(props.selectedAdjustment.value && props.selectedAdjustment.value !== "")? false:true}
                            variant="primary"
                          >
                            <i className="fa fa-plus"></i>
                          </Button>
                      </Form.Group>  
                    </Col>
                     <Col className="text-center align-self-center" md={1}> 
                  </Row>   */}
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col md="12">
            <h5>Adjustments</h5>
            
            <Card>
              <Card.Body>
              <TableScrollbar rows={5}>
              <Table className="table-hover table-striped w-full">
                  <thead style={{ borderBottom: "3px solid #222" }}>
                    <tr>
                      {/* <th>Change Order</th> */}
                      <th>Sequence</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Free Goods?</th>
                      <th>Bundle?</th>
                      <th>Optional Code</th>
                      {/* <th>Delete</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    { adjSch.scheduleDetails.map((detailRecord, i) => (
                      <tr key={i}>
                        {/* <td style={{position: "relative"}}>
                          <Button
                            onClick={(event) => props.onChangeOrder(event, adj.code, "up")}
                            size="sm"
                            className="btn-link remove"
                            name="adjustmentOrder"
                            value={adj.code}
                          >
                            <i className="far fa-angle-up"/>
                          </Button>
                          <Button
                            onClick={(event) => props.onChangeOrder(event, adj.code, "down")}
                            size="sm"
                            className="btn-link remove"
                            name="adjustmentOrder"
                            value={adj.code}
                          >
                            <i className="far fa-angle-down"/>
                          </Button>
                        </td> */}
                        <td>{i+1}</td>
                        <td>{detailRecord.adjustment.code}</td>
                        <td>{detailRecord.adjustment.description}</td>
                        <td>{detailRecord.adjustment.freeGoods === true ? "Yes" : "No"}</td>
                        <td>{detailRecord.adjustment.bundle === true    ? "Yes" : "No"}</td>
                        <td>{detailRecord.adjustment.optionalCode}</td>
                        {/* <td>
                          <Button
                            onClick={(event) => props.onRecordDelete(event, adj.code)}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                            name="adjustmentDelete"
                            value={adj.code}
                          >
                            <i className="fa fa-times" />
                          </Button>

                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </TableScrollbar>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
        </Modal.Body>
      </Modal>
    </>
    );
  }
  else {
    return null;
  }
}

export default AdjSchedViewModal;