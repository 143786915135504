import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { useAuth } from '../../use-auth'
import { StatusCodes } from 'http-status-codes';

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Nav,
  Container,
  Col,
} from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock as lock } from "@fortawesome/pro-regular-svg-icons";

function ForgotPasswordPage(
  //props
  ) {
  const auth = useAuth();

  const {t} = useTranslation();
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [invalidSend, setInvalidSend] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authProblem, setAuthProblem] = useState(false);
  const [successful, setSuccessful] = useState(false);

  let appName = t('app.name.full');
  let title = t('reset-password.title', {"app_title": appName});

  React.useEffect(() => {
    document.title = title
  }, [title]);

  const [cardClasses, setCardClasses] = useState("card-hidden");

  function validateUsername(value) {
    setUsername(value);
    //Regex Pattern
    const regex = /^[a-zA-Z0-9_.-]*$/;
    if (regex.test(value)) {
      setUsernameError(false);
    } 
    else {
      setUsernameError(true);
    }
  }

  const onChangeUsername = (e) => {
    //console.log(e.target.value);
    let trim = e.target.value;
    validateUsername(trim.replace(/ /g, ''));
    setInvalidSend(false);
    setAuthError(false);
  };

  let handleSubmit = async e =>{
    e.preventDefault();
    setAuthError(false);
    if(username === ""){
      setInvalidSend(true);
      setAuthError(false);
      setAuthProblem(false);
    }
    else if(!usernameError){
      //Unit Test the submit
      /* if(Object.keys(props).length !== 0){
        setAuthError(false);
        setSuccessful(true);
        props.handleSubmit(username);
        return;
      } */
      let response = await auth.forgotPassword(username);
      if(response.status === StatusCodes.UNAUTHORIZED){
        setAuthError(true);
        setUsername('');
      }
      else if(response.status === StatusCodes.OK){
        setSuccessful(true);
      }
      else{
        setAuthProblem(true);
        setUsername('');
      }
    }
  }

  const loginLink = (<Nav.Link to="/auth/login" as={Link} className="px-0 py-0 d-inline"></Nav.Link>);
  const backLogin = (<Nav.Link to="/auth/login" as={Link} className="px-0 py-0">{t('login.back')}</Nav.Link>);

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  return (
    <>
      <div
        className="full-page section-image"
        data-color="white"
      >
        <div className="content d-flex align-items-center p-0" style={{backgroundColor: "#fff", minHeight: "calc(100vh - 173px)"}}>
          <Container>
            <div className="row login">
            <Col className="mx-auto" md="4" sm="6">
              <Form className="form" onSubmit={handleSubmit} data-testid="forgot-password-form">
                <Card className={cardClasses + " card-login"}
                      style={{borderRadius: 0}}>
                  <Card.Header>
                    <div className="text-center px-4 mx-auto">
                      <FontAwesomeIcon icon={lock} size="5x" alt="login"/>
                    </div>
                    <div className="text-center px-4 py-1 mx-auto">
                      <h3>{t('reset-password.subtitle')}</h3>
                    </div>
                  </Card.Header>
                  
                    <Card.Body>
                      <div className="px-5">
                      {invalidSend ? (<div className="alert alert-danger mt-1">{t('form.invalid')}</div>) : ""}
                      {usernameError ? (<div className="alert alert-danger mt-1">{t('username.error')}</div>) : ""}
                      {authError ?  (<div className="alert alert-danger mt-1" role="alert">{t('auth.unauthorized')}</div>) : ""}
                      {authProblem ?  (<div className="alert alert-danger mt-1" role="alert">{t('auth.problem')}</div>) : ""}
                      {successful ? (<div className="alert alert-success mt-1" role="alert">{t('reset-password.successful')}</div>) :
                        (
                          <>
                            <Form.Group className="mb-3" >
                              <label htmlFor="username">{t('username.label')}</label>
                              <Form.Control
                                autoFocus
                                id="username"
                                name="username"
                                placeholder={t('username.placeholder')}
                                type="text"
                                isInvalid={invalidSend}
                                onChange={onChangeUsername}
                                value={username}
                              />
                            </Form.Group>
                            <Button className="btn-wd" type="submit" variant="success" style={{width: "100%"}}>
                              Submit
                            </Button>
                          </>
                        )
                      }
                      </div>
                    </Card.Body>
                  
                  <Card.Footer className="px-4 text-center">
                      <div className="text-center mx-auto d-inline p" >
                        {successful ? (<><Trans i18nKey="login.return">{loginLink}</Trans></>) : (<>{backLogin}</>)}
                      </div>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

ForgotPasswordPage.whyDidYouRender = true;

export default ForgotPasswordPage;