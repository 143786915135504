import React, {useState, useEffect, useRef} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {useAuth} from '../../use-auth'
import {StatusCodes} from 'http-status-codes';
import NotificationAlert from "react-notification-alert";

// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Nav,
    Container,
    Col,
    InputGroup,
    Alert
} from "react-bootstrap";

//Global variables
const initialTextValue = "";

function LoginPage({...loginProps}) {
    console.debug("Entering Login component.");
    const history = useHistory();
    const auth = useAuth();

    const {t} = useTranslation();

    const focusInputField = useRef(null);
    const [loginInfo, setLoginInfo] = useState({
        username: initialTextValue,
        password: initialTextValue
    });
    // const [username, setUsername] = useState(initialTextValue);
    // const [password, setPassword] = useState(initialTextValue);
    const [passwordShown, setPasswordShown] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [invalidSend, setInvalidSend] = useState(false);
    const [authError, setAuthError] = useState(false);
    const [authProblem, setAuthProblem] = useState(false);
    const [idleNotification, setIdleNotification] = useState(false);
    const logoutNotificationRef = React.useRef(null);

    const appName = t('app.name.full');
    const title = t('login.title', {"app_title": appName});

    const idle = loginProps.location.state?.idle && loginProps.location.state.idle;

    useEffect(() => {
        console.debug("Login Idle = " + idle);
        document.title = title;
        if(idle == true){
            const options = {
                place: "tc",
                message: t('idle.message'),
                type: "info",
            };

            logoutNotificationRef.current.notificationAlert(options);
        }
    }, [title]);

    const [cardClasses, setCardClasses] = useState("card-hidden");

    useEffect(() => {
        setTimeout(function () {
            setCardClasses("");
        }, 1000);
    });
    
    const validateUsername = (value) => {
        // setUsername(value);
        setLoginInfo({
            username: value,
            password: loginInfo.password
        });
        //Regex Pattern
        const regex = /^[a-zA-Z0-9_.-]*$/;
        if (regex.test(value)) {
            setUsernameError(false);
        } else {
            setUsernameError(true);
        }
    };

    const onPasswordChange = (e) => {
        let trim = e.target.value;
        // setPassword(trim.replace(/ /g, ''));
        setLoginInfo({
            username: loginInfo.username,
            password: trim.replace(/ /g, '')
        });
        setInvalidSend(false);
        setAuthError(false);
    };

    const onUsernameChange = (e) => {
        let trim = e.target.value;
        // console.log(trim)
        validateUsername(trim.replace(/ /g, ''));
        setInvalidSend(false);
        setAuthError(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        //Mock Test - Remove comments to run the unit test
        /* if(Object.keys(props).length !== 0){
            console.log(props);
            props.handleSubmit(username, password);
        } */

        if (
            loginInfo.username === "" || loginInfo.password === ""
            // username === "" || password === ""
            ) {
            setInvalidSend(true);
            setAuthError(false);
            setAuthProblem(false);
        } else if (!usernameError) {
            let resp = await auth.login(
                // username, password
                loginInfo.username, loginInfo.password
            );
            // console.log(resp)
            if (resp.status) {
                if (resp.status === StatusCodes.UNAUTHORIZED) {
                    setAuthError(true);
                    // setAuthProblem(false);
                    // setUsername(initialTextValue);
                    // setPassword(initialTextValue);
                    setLoginInfo({
                        username: initialTextValue,
                        password: initialTextValue
                    });
                    focusInputField.current.focus();
                } 
                else if (resp.status === StatusCodes.OK) {
                    //setUsername(initialTextValue);
                    //setPassword(initialTextValue);
                    setLoginInfo({
                        username: initialTextValue,
                        password: initialTextValue
                    });
                    history.push("/app/adjustment");
                } 
                else {
                    setAuthProblem(true);
                    // setAuthError(false);
                    setLoginInfo({
                        username: initialTextValue,
                        password: initialTextValue
                    });
                    focusInputField.current.focus();
                    // setUsername(initialTextValue);
                    // setPassword(initialTextValue);
                }
            } 
            else if (resp.error) {
                setAuthProblem(true);
            }
        }
    };

    const AlertMessage = () => {
        const messageCode = invalidSend ? 'form.invalid' : authError ? 'auth.unauthorized' : authProblem ? 'auth.problem' : null;

        if(messageCode) {
            return <Alert className="mt-1" variant="danger">{t(messageCode)}</Alert>;
        }
        else{
            return <></>;
        }
    };

    return (
        <>
            <div className="full-page section-image" data-color="white">
                <div className="content d-flex align-items-center p-0 bg-white login-height">
                    <Container>
                        <div className="row login">
                            <Col className="mx-auto" md="4" sm="6">
                                <Form className="form" method="" data-testid="login" onSubmit={handleSubmit}>
                                    <Card className={cardClasses + " card-login"}>
                                        <Card.Header>
                                            <div className="text-center px-4 mx-auto" style={{position: "relative", width: "100%"}}>
                                                <img 
                                                    className="mx-auto text-center login-logo"
                                                    style={{maxHeight: "150px"}}
                                                    src={require("assets/logo/logoPriceScheduler.png").default}
                                                    alt="PricePoint Logo" />
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="px-5">
                                               <AlertMessage />
                                                <Form.Group className="mb-3">
                                                    <label htmlFor="username" className="control-label">{t('username.label')}</label>
                                                    <Form.Control
                                                        autoFocus
                                                        id="username"
                                                        name="username"
                                                        placeholder={t('username.placeholder')}
                                                        type="text"
                                                        isInvalid={invalidSend && (
                                                            loginInfo.username === ""
                                                            // username === ""
                                                        )}
                                                        onChange={onUsernameChange}
                                                        maxLength="40"
                                                        value={
                                                            loginInfo.username
                                                            // username
                                                        }
                                                        ref={focusInputField}
                                                    />
                                                    {usernameError ?
                                                        (<span className="invalid-feedback">{t('username.error')}</span>) : ("")}
                                                </Form.Group>
                                                <Form.Group>
                                                    <label htmlFor="pass" className="control-label">{t('password.label')}</label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            id="pass"
                                                            name="pass"
                                                            placeholder={t('password.placeholder')}
                                                            type={passwordShown ? "text" : "password"}
                                                            isInvalid={invalidSend && ( 
                                                                // password === ""
                                                                loginInfo.password === ""
                                                            )}
                                                            onChange={onPasswordChange}
                                                            maxLength="40"
                                                            value={
                                                                loginInfo.password
                                                                // password
                                                            }
                                                        />
                                                        <InputGroup.Text onClick={() => setPasswordShown(!passwordShown)}>
                                                            {passwordShown ?
                                                                (<i className="fal fa-eye-slash"/>) : (<i className="fal fa-eye"/>)}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Form.Group>
                                                <div className="d-flex pt-2 pb-0">
                                                    <label>
                                                        <Nav.Link to="/auth/change-password" as={Link} className="px-0 py-0">
                                                            {t('change-password.label')}
                                                        </Nav.Link>
                                                    </label>
                                                </div>
                                                <div className="d-flex pt-0 pb-1">
                                                    <label>
                                                        <Nav.Link to="/auth/forgot-password" as={Link} className="px-0 py-0">
                                                            {t('reset-password.label')}
                                                        </Nav.Link>
                                                    </label>
                                                </div>
                                                <Button className="btn-wd w-100" type="submit" variant="success">
                                                    {t('login.label')}
                                                </Button>
                                            </div>
                                        </Card.Body>
                                        <Card.Footer className="px-4">
                                        </Card.Footer>
                                    </Card>
                                </Form>
                            </Col>
                        </div>
                    </Container>
                </div>
                <div className="rna-container">
                  <NotificationAlert ref={logoutNotificationRef}/>
                </div>
            </div>
        </>
    );
}

LoginPage.whyDidYouRender = true;

export default LoginPage;