/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import AdjustmentPage from "views/Adjustments/AdjustmentPage.js";
import LoginPage from "views/Auth/LoginPage.js";
import ForgotPassPage from "views/Auth/ForgotPasswordPage.js";
import ResetPassPage from "views/Auth/ResetPasswordPage.js";
import ChangePassPage from "views/Auth/ChangePasswordPage.js";
import AdjustmentSchedules from "views/AdjustmentSchedules/AdjustmentSchedulesPage.jsx";

var routes = [
    {
        path: "/adjustment",
        layout: "/app",
        name: "Adjustments",
        icon: "nc-icon nc-chart-bar-32",
        component: AdjustmentPage,
    },
    {
        path: "/adjustment-schedule",
        layout: "/app",
        name: "Adjustment Schedule",
        icon: "nc-icon nc-single-copy-04",
        component: AdjustmentSchedules,
    },
    {
        path: "/login",
        layout: "/auth",
        name: "Login Page",
        mini: "LP",
        component: LoginPage,
    },
    {
        path: "/change-password",
        layout: "/auth",
        name: "Change Password",
        icon: "nc-icon nc-chart-bar-32",
        component: ChangePassPage,
    },
    {
        path: "/forgot-password",
        layout: "/auth",
        name: "Forgot Password Page",
        mini: "FPP",
        component: ForgotPassPage,
    },
    {
        path: "/reset-password",
        layout: "/auth",
        name: "Reset Password Page",
        mini: "RPP",
        component: ResetPassPage,
    }
    // {
    //     path: "/lock-screen-page",
    //     layout: "/auth",
    //     name: "Lock Screen Page",
    //     mini: "LSP",
    //     component: LockScreenPage,
    // }
];
export default routes;