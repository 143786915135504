import React, {useEffect, useState, useCallback} from "react";
import {useTranslation} from 'react-i18next';
import ReactTable from "../ReactTable/ReactTable.js";
import {FormatDate, FormatBoolean} from "../../utils/formatters"
import {ValuesSelectColumnFilter} from "../ReactTable/ValuesSelectColumnFilter";
import {DatePickerFilter} from "../ReactTable/DatePickerFilter";
import {OPT_CODE_OPTIONS} from "../../constants"
import {useAuth} from "../../use-auth";

function AdjustmentTable({data, selectedRowHandler, fetchData, loading, pageCount, totalRecords}) {
  const {t} = useTranslation();
  const auth = useAuth();
  const [clearFilters, setClearFilters] = useState(false);

  useEffect(() => {
    setClearFilters(true);
  }, [auth.getSelectedOrgEntity()]);

  console.debug("Render table.");
  return <>
    <div className="filterable-table-container adjustments">
      <ReactTable
        clearFilters={clearFilters}
        handleClearFilters={setClearFilters}
        disableActions={true}
        selectedRowHandler={selectedRowHandler}
        totalRecords={totalRecords}
        hiddenColumns={["id", "bundle", "percentage"]}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        totalRecords={totalRecords}
        manualSortBy={true}
        sortByCol={
          React.useMemo(() => {
            return [{id: "lastUpdated", desc: "true"}];
          }, [])
        }
        manualFilters={true}
        columns={[
          {
            id: "id",
            Header: t('id.label'),
            accessor: "id",
          },
          {
            id: "code",
            Header: t('adjustment.code.label'),
            accessor: "code",
            addClassName: "code-col"
          },
          {
            id: "description",
            Header: t('adjustment.description.label'),
            accessor: "description"
          },
          {
            id: "optionalCode",
            Header: t('adjustment.optional.label'),
            accessor: dat => dat.optionalCode ? t(`adjustment.optionalCode.${dat.optionalCode}`) : '',
            Filter: ValuesSelectColumnFilter,
            selectFilterOptions: [{value: 'null', label: `[${t('empty.label')}]`},
              ...OPT_CODE_OPTIONS.map(o => {
                return {value: o.value, label: t(o.textCode)}
              })],
            addClassName: 'optional-code-col'
          },
          {
            id: "freeGoods",
            Header: t('adjustment.freeGoods.label'),
            accessor: dat => FormatBoolean(dat.freeGoods, 'YN', t),
            Filter: ValuesSelectColumnFilter,
            selectFilterOptions: [
              {value: "true", label: FormatBoolean("true", 'YN', t)},
              {value: "false", label: FormatBoolean("false", 'YN', t)}
            ],
            addClassName: "free-goods-col"
          },
          {
            id: "bundle",
            Header: t('adjustment.bundle.label'),
            accessor: dat => FormatBoolean(dat.bundle, 'YN', t),
            addClassName: "bundle-col"
          },
          {
            id: "percentage",
            Header: t('adjustment.percentage.label'),
            accessor: dat => FormatBoolean(dat.bundle, 'YN', t),
            addClassName: "percentage-col"
          },
          {
            id: "enabled",
            Header: t('adjustment.enabled.label'),
            accessor: dat => FormatBoolean(dat.enabled, 'YN', t),
            Filter: ValuesSelectColumnFilter,
            selectFilterOptions: [
              {value: "true", label: FormatBoolean("true", 'YN', t)},
              {value: "false", label: FormatBoolean("false", 'YN', t)}
            ],
            addClassName: "enabled-col"
          },
          {
            id: "lastUpdated",
            Header: t('adjustment.lastUpdated.label'),
            accessor: dat => FormatDate(dat.lastUpdated, t('date.short_format')),
            Filter: DatePickerFilter,
            addClassName: "last-updated-col"
          },
        ]}
      />
    </div>
  </>;
}

export default AdjustmentTable;