import ReactDatetime from "react-datetime";
import {useTranslation} from "react-i18next";

export function DatePickerFilter({column: {setFilter, id}}) {
    const {t} = useTranslation();
    return (
        <>
            <ReactDatetime
                inputProps={
                    {
                        className: "form-control",
                        placeholder: t('select.placeholder'),
                        onChange: (e) => {
                            setFilter(e.target.value);
                          }
                    }
                }
                onChange={(momentDate) => {
                    if (momentDate && typeof momentDate !== 'string') {
                        const date = momentDate.toDate();
                        const dateStr = date.toISOString();
                        setFilter(dateStr);
                    }
                }}
                closeOnSelect={true}
                timeFormat={false}
                >

            </ReactDatetime>
        </>
    );
}