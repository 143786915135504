import React, {useEffect} from "react";
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactTable from "../ReactTable/ReactTable.js";
import {FormatDate} from "../../utils/formatters";
import {useAuth} from "../../use-auth";
import SweetAlert from "react-bootstrap-sweetalert";
import {Button} from "react-bootstrap";
import {deleteAdjustmentSchedule,saveScheduleHistory} from "utils/adjustment-data.js";
import {StatusCodes} from "http-status-codes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PermissionsGate from "../Auth/PermissionGate.js";
import {SCOPES} from '../../role-permissions';
import {DatePickerFilter} from "../ReactTable/DatePickerFilter";

function AdjustmentSchedulesTable({
                                    data,
                                    selectedRowHandler,
                                    fetchData,
                                    loading,
                                    setLoading,
                                    pageCount,
                                    setDataUpdatedCnt,
                                    dataUpdatedCnt,
                                    totalRecords,
                                    handleEditAction,
                                    notify
                                  }) {

  const {t} = useTranslation();
  const auth = useAuth();
  const selOrgEnt = auth.getSelectedOrgEntity();
  const [clearFilters, setClearFilters] = useState(false);

  useEffect(() => {
    setClearFilters(true);
  }, [selOrgEnt]);

  const [alert, setAlert] = useState(false);

  const onRecordDelete = async (orgEntity, scheduleId) => {
    const tokenResp = await auth.getToken();
    if (tokenResp.status !== StatusCodes.OK) {
       console.error("Could not get the access token for the resources.");
       return;
    }
    let token = tokenResp.token;
    const saveResp = await saveScheduleHistory(token, orgEntity, 'D', scheduleId);
    const response = await deleteAdjustmentSchedule(token, orgEntity, scheduleId);
    if (response.status === StatusCodes.NO_CONTENT || response.status === StatusCodes.OK) {
      setLoading(false);
      closeAlert();
      notify("primary", "tc", `Adjustment Schedule deleted successfully.`);
      setDataUpdatedCnt(dataUpdatedCnt + 1);
    }
    else {
      response.json()
        .then((responseData) => {
          setLoading(false);
          closeAlert();
          console.error("Error while deleteing schedule id = " + scheduleId);
          notify("primary", "tc", `${responseData.message}`);
        });
    }
  };

  const openWarnDeleteAlert = (row, title) => {
      // console.log(row)
    const adjustmentScheduleCode = row.code;
    setAlert(
      <SweetAlert
        warning
        style={{display: "block"}}
        title={t('delete.alert.title')}
        onConfirm={() => onRecordDelete(auth.getSelectedOrgEntity(), row.id)}
        onCancel={() => closeAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText={<FontAwesomeIcon icon={["fal", "check"]}/>}
        cancelBtnText={<FontAwesomeIcon icon={["fal", "times-circle"]}/>}
        showCancel>
        <span>{t('delete.alert.message', {scheduleCode: adjustmentScheduleCode})}</span>
      </SweetAlert>
    );
  };

  const closeAlert = () => {
    setAlert(null);
  };

  return (
    <div className="filterable-table-container">
      <ReactTable
        clearFilters={clearFilters}
        handleClearFilters={setClearFilters}
        disableActions={false}
        selectedRowHandler={selectedRowHandler}
        totalRecords={totalRecords}
        hiddenColumns={["id"]}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        manualSortBy={true}
        sortBy={React.useMemo(() => {
          return [{id: "lastUpdated", desc: "true"}];
        }, [])}
        manualFilters={true}
        columns={[
          {
            id: "id",
            Header: t('id.label'),
            accessor: "id"
          },
          {
            id: "code",
            Header: t('adjustment.code.label'),
            accessor: "code",
            addClassName: "code-col"
          },
          {
            id: "description",
            Header: t('adjustment.description.label'),
            accessor: "description"
          },
          {
            id: "startDate",
            Header: t('adjustmentSchedules.startDate.label'),
            accessor: dat => FormatDate(dat.startDate, t('date.short_format')),
            Filter: DatePickerFilter
          },
          {
            id: "endDate",
            Header: t('adjustmentSchedules.endDate.label'),
            accessor: dat => FormatDate(dat.endDate, t('date.short_format')),
            Filter: DatePickerFilter
          },
          {
            id: "activeSchedule",
            Header: "Active? ",
            accessor: dat => (<div style={{textAlign: "center"}}>
                {dat.activeSchedule === true ? "Yes" : "No"}
              </div>
            )
          },
          {
            Header: "Actions",
            accessor: row => (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a edit kind of action */}
                <PermissionsGate scopes={[SCOPES.canEdit]}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditAction(row);
                    }}
                    variant="warning"
                    size="sm"
                    className="text-warning btn-link edit"
                  >
                    <i className="fa fa-edit"/>
                  </Button>
                </PermissionsGate>
                {/* use this button to remove the data row */}
                <Button
                        onClick={ (e) => {
                          e.stopPropagation();
                          openWarnDeleteAlert(row, t('save.label')); 
                        }}
                        variant="danger"
                        size="sm"
                        className="btn-link remove text-danger"
                      >
                        <i className="fa fa-times" />
                      </Button>
              </div>
            ),
            sortable: false,
            filterable: false
          },
        ]}
      />
      {alert}
    </div>);
}

export default AdjustmentSchedulesTable;
