import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation, Trans} from 'react-i18next';
import {useAuth} from '../../use-auth'
import {StatusCodes} from 'http-status-codes';

// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Nav,
    Container,
    Col,
    InputGroup,
    Alert
} from "react-bootstrap";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faKey as key} from "@fortawesome/pro-regular-svg-icons";

function ChangePassword(props) {
    //console.log("o");
    const auth = useAuth();

    const {t} = useTranslation();
    const [username, setUsername] = useState('');
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [cPasswordShown, setCPasswordShown] = useState(false);
    const [style, setStyle] = useState({visibility: "hidden"});
    const [usernameError, setUsernameError] = useState(false);
    const [displayPassError, setPasswordError] = useState(false);
    const [identicalPassword, setIdenticalPassword] = useState(false); //New and Confirmation
    const [identicalOldNewPassword, setIdenticalOldNewPassword] = useState(false);
    const [invalidSend, setInvalidSend] = useState(false);
    const [authError, setAuthError] = useState(false);
    const [authProblem, setAuthProblem] = useState(false);
    const [successful, setSuccessful] = useState(false);

    let appName = t('app.name.full');
    let title = t('change-password.title', {"app_title": appName});

    React.useEffect(() => {
        document.title = title
    }, [title]);

    const [cardClasses, setCardClasses] = React.useState("card-hidden");

    React.useEffect(() => {
        setTimeout(function () {
            setCardClasses("");
        }, 1000);
    });

    //This function validate the new password and confirmation password to have a valid pattern.
    function validatePassword(value) {
        //Regex Pattern
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.])[A-Za-z\d@$!%*?&#.]{8,40}$/;
        if (regex.test(value)) {
            return false;
        } else {
            return true;
        }
    }

    //This function validate the username
    function validateUsername(value) {
        setUsername(value);
        //Regex Pattern
        const regex = /^[a-zA-Z0-9_.-]*$/;
        if (regex.test(value)) {
            setUsernameError(false);
        } else {
            setUsernameError(true);
        }
    }

    //This function validate if current password is not the same as new password; and new password is the same as the confirmation password.
    const validatePasswordIdentical = async () => {
        if (oldpassword === newpassword) {
            return "Identical";
        } 
        else {
            if (newpassword !== cpassword) {
                return "NotIdentical"
            } else {
                return true;
            }
        }
    };

    let handleSubmit = async e => { //This function execute after clicking submit button.
        e.preventDefault();
        //console.log("Handling change password for username => " + username + " oldpassword => " + oldpassword + " newpassword => " + newpassword);
        //Unit Test the submit
        /* if(Object.keys(props).length !== 0){
            //console.log(username + "/" + oldpassword + "/" + newpassword);
            props.handleSubmit(username, oldpassword, newpassword);
            if(props.unsuccessful !== undefined){
                setAuthError(true);
            }
            else{
                setSuccessful(true);
            }
            return;
        } */
        //This if statement will turn off any other problems to present that the input field(s) is(are) empty.
        if (username === "" || oldpassword === "" || newpassword === "" || cpassword === "") {
            setIdenticalPassword(false);
            setIdenticalOldNewPassword(false);
            setInvalidSend(true);
        } else {
            const res = await validatePasswordIdentical();
            if (!usernameError && !displayPassError && (res === true)) {
                let response = await auth.changePassword(username, oldpassword, newpassword);
                if (response.status === StatusCodes.UNAUTHORIZED) { //Status 401
                    setAuthError(true);
                    setUsername('');
                    setCPassword('');
                    setNewPassword('');
                    setOldPassword('');
                } else if (response.status === StatusCodes.NO_CONTENT) { //Status 204
                    setSuccessful(true);
                } else { //Status 5**
                    setAuthProblem(true);
                    setUsername('');
                    setCPassword('');
                    setNewPassword('');
                    setOldPassword('');
                }
            } else if (res === "Identical") { //If new password is the same as the current password
                setIdenticalOldNewPassword(true);
                setUsername('');
                setCPassword('');
                setNewPassword('');
                setOldPassword('');
            } else if (res === "NotIdentical") { //If the confirmation is not the same as the new password.
                setIdenticalPassword(true);
                setUsername('');
                setCPassword('');
                setNewPassword('');
                setOldPassword('');
            }
        }
    };

    const onChangeUsername = (e) => {
        let trim = e.target.value;
        validateUsername(trim.replace(/ /g, ''));
        setInvalidSend(false);
        setAuthError(false);
    };

    const onChangeOldPassword = (e) => {
        let trim = e.target.value;
        setOldPassword(trim.replace(/ /g, ''));
        setInvalidSend(false);
        setIdenticalPassword(false);
        setIdenticalOldNewPassword(false);
        setAuthError(false);
    };

    const onNewPasswordChange = (e) => {
        let trim = e.target.value;
        trim = trim.replace(/ /g, '');
        setNewPassword(trim);
        const res = validatePassword(trim);
        setPasswordError(res);
        setInvalidSend(false);
        setIdenticalPassword(false);
        setIdenticalOldNewPassword(false);
        setAuthError(false);
    };

    const onCurrentPasswordChange = (e) => {
        let trim = e.target.value;
        trim = trim.replace(/ /g, '');
        setCPassword(trim);
        const res = validatePassword(trim);
        setPasswordError(res);
        setInvalidSend(false);
        setIdenticalPassword(false);
        setAuthError(false);
    };

    const onNewPasswordShow = () => setNewPasswordShown(!newPasswordShown);
    const onOldPasswordShow = () => setOldPasswordShown(!oldPasswordShown);
    const onCurrentPasswordShow = () => setCPasswordShown(!cPasswordShown);

    const loginLink = (<Nav.Link to="/auth/login" as={Link} className="px-0 py-0 d-inline"></Nav.Link>);
    const backLogin = (<Nav.Link to="/auth/login" as={Link} className="px-0 py-0">{t('login.back')}</Nav.Link>);

    const canFillForm = () => {
        return invalidSend ? {canFill: true, message: 'form.invalid', msgType: "danger"} :
            identicalPassword ? {canFill: true, message: 'form.matchPass', msgType: "danger"} :
                identicalOldNewPassword ? {canFill: true, message: 'form.oldNewMatch', msgType: "danger"} :
                    displayPassError ? {canFill: true, message: 'form.requirements', msgType: "danger"} :
                        authError ? {canFill: true, message: 'auth.unauthorized', msgType: "danger"} :
                            authProblem ? {canFill: true, message: 'auth.problem', msgType: "danger"} :
                                successful ? {canFill: false, message: 'change-password.successful', msgType: "success"} :
                                    {canFill: true};
    };

    return (
        <>
            <div className="full-page section-image" data-color="white">
                <div className="content d-flex align-items-center p-0 bg-white">
                    <Container>
                        <div className="row login">
                            <Col className="mx-auto" md="4" sm="6">
                                <Form 
                                  className="form"
                                  onSubmit={handleSubmit} 
                                  data-testid="change-password-form"
                                  >
                                    <Card className={cardClasses + " card-login"}>
                                        <Card.Header>
                                            <div className="text-center px-4 mx-auto">
                                                <FontAwesomeIcon icon={key} size="5x" alt="login"/>
                                            </div>
                                            <div className="text-center px-4 py-1 mx-auto">
                                                <h3>Change Password</h3>
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="px-5">
                                                {canFillForm().canFill ?
                                                    <>
                                                        {
                                                            canFillForm().msgType ?
                                                                <Alert variant={canFillForm().msgType} className="mt-1">
                                                                    {t(canFillForm().message)}
                                                                </Alert> : <></>
                                                        }
                                                        <Form.Group className="mb-3">
                                                            <label htmlFor="username">{t('username.label')}</label>
                                                            <Form.Control
                                                                autoFocus
                                                                id="username"
                                                                name="username"
                                                                placeholder={t('username.placeholder')}
                                                                type="text"
                                                                isInvalid={((username === "") && (invalidSend === true))}
                                                                onChange={onChangeUsername}
                                                                value={username}/>
                                                            {
                                                                usernameError ?
                                                                    (<span className="invalid-feedback">{t('username.error')}</span>) :
                                                                    ("")
                                                            }
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <label htmlFor="oldPassword">
                                                                {t('change-password.currentpassword.label')}
                                                            </label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    id="oldPassword"
                                                                    name="oldPassword"
                                                                    placeholder={t('change-password.currentpassword.placeholder')}
                                                                    type={oldPasswordShown ? "text" : "password"}
                                                                    isInvalid={((oldpassword === "") && (invalidSend === true))}
                                                                    onChange={onChangeOldPassword}
                                                                    maxLength="40"
                                                                    value={oldpassword}/>
                                                                <InputGroup.Text onClick={onOldPasswordShow}>
                                                                    {oldPasswordShown ?
                                                                        (<i className="fal fa-eye-slash"/>) :
                                                                        (<i className="fal fa-eye"/>)}
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" style={{position: "relative"}}>
                                                            <div className="alert messageBox mt-1" style={style}>{t('form.password')}</div>
                                                            <label htmlFor="newPassword">{t('change-password.newpassword.label')}</label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    id="newPassword"
                                                                    name="newPassword"
                                                                    placeholder={t('change-password.newpassword.placeholder')}
                                                                    type={newPasswordShown ? "text" : "password"}
                                                                    isInvalid={((displayPassError || (newpassword === "")) && (invalidSend === true))}
                                                                    onChange={onNewPasswordChange}
                                                                    onMouseEnter={(e) => {
                                                                        setStyle({visibility: "visible"})
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        setStyle({visibility: "hidden"})
                                                                    }}
                                                                    maxLength="40"
                                                                    value={newpassword}/>
                                                                <InputGroup.Text onClick={onNewPasswordShow}>
                                                                    {newPasswordShown ?
                                                                        (<i className="fal fa-eye-slash"/>) :
                                                                        (<i className="fal fa-eye"/>)}
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <label htmlFor="cPassword">{t('change-password.cpassword.label')}</label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    id="cPassword"
                                                                    name="cPassword"
                                                                    placeholder={t('change-password.cpassword.label')}
                                                                    type={cPasswordShown ? "text" : "password"}
                                                                    isInvalid={((displayPassError || (cpassword === "")) && invalidSend)}
                                                                    onChange={onCurrentPasswordChange}
                                                                    maxLength="40"
                                                                    value={cpassword}/>
                                                                <InputGroup.Text onClick={onCurrentPasswordShow}>
                                                                    {cPasswordShown ?
                                                                        (<i className="fal fa-eye-slash"/>) :
                                                                        (<i className="fal fa-eye"/>)}
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Button className="btn-wd w-100" type="submit" variant="success" disabled={displayPassError}>
                                                            {t('submit.label')}
                                                        </Button>
                                                    </> :
                                                    <Alert variant={canFillForm().msgType} className="mt-1">
                                                        {t(canFillForm().message)}
                                                    </Alert>
                                                  }
                                            </div>
                                        </Card.Body>

                                        <Card.Footer className="px-4 text-center">
                                            <div className="text-center mx-auto d-inline p">
                                                {successful ? (<><Trans i18nKey="login.return" t={t}>{loginLink}</Trans></>) : (<>{backLogin}</>)}
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                </Form>
                            </Col>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}

ChangePassword.whyDidYouRender = true;

export default ChangePassword;