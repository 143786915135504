import React, {createContext, useContext} from "react";
import {StatusCodes} from "http-status-codes";
import {useCookies} from "react-cookie";

const AuthContext = createContext(null);

const pricePointAuth = {
  refresh(refreshToken) {
    const baseURL = process.env.REACT_APP_BASE_AUTH_URL;
    return fetch(baseURL + '/api/auth/refresh-token', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({refreshToken: refreshToken})
    }).then(data => {
      if (data.status === StatusCodes.OK) {
        return data.json().then((json) => {
          json["status"] = StatusCodes.OK;
          return json;
        });
      }
      else {
        return {status: data.status};
      }
    });
  },
  login(username, password) {
    const baseURL = process.env.REACT_APP_BASE_AUTH_URL;
    return fetch(baseURL + '/api/auth/login', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username: username, password: password})
    }).then(data => {
      if (data.status === StatusCodes.OK) {
        return data.json().then((json) => {
          json["status"] = StatusCodes.OK;
          return json;
        });
      }
      else {
        return {status: data.status};
      }
    });
  },
  changePassword(username, password, newpassword) {
    const baseURL = process.env.REACT_APP_BASE_AUTH_URL;
    return fetch(baseURL + '/api/auth/users/change-user-password', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        "username": username,
        "password": password,
        "newPassword": newpassword
      })
    }).then(data => {
      if (data.status === StatusCodes.NO_CONTENT) {
        return {status: data.status};
      }
      else {
        return {status: data.status, errorMessage: data.errorMessage, message: data.message};
      }
    });
  },
  forgotPassword(username) {
    const baseURL = process.env.REACT_APP_BASE_AUTH_URL;
    return fetch(baseURL + '/api/auth/users/' + username + '/reset-password', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        "callback": process.env.REACT_APP_FORGOT_PASSWORD_CALLBACK
      })
    }).then(data => {
      if (data.status === StatusCodes.OK) {
        return data.json().then((json) => {
          json["status"] = StatusCodes.OK;
          return json;
        });
      }
      else {
        return {status: data.status, errorMessage: data.errorMessage, message: data.message};
      }
    });
  },
  resetPassword(token, username, password) {
    const baseURL = process.env.REACT_APP_BASE_AUTH_URL;
    return fetch(baseURL + '/api/auth/users/' + username + '/change-password/' + token, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        "newPassword": password
      })
    }).then(data => {
      if (data.status === StatusCodes.NO_CONTENT) {
        return {status: data.status};
      }
      else {
        return {status: data.status, errorMessage: data.errorMessage, message: data.message};
      }
    });
  },
  logout() {
    //TODO Later on invalidate token with API call. Maybe?
    return true;
  }
};

/**
 *
 * @param children
 * @returns {*}
 * @constructor
 */
export function ProvideAuth({children}) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

/**
 *
 * @returns {null}
 */
export function useAuth() {
  return useContext(AuthContext);
}

function useProvideAuth() {
  const [cookies, setCookie, removeCookie] = useCookies([
    'user',
    'userOrgEntities',
    'userRt',
    'userRoles',
    "selectedOrgEnt"]);

  const getToken = async () => {
    const tokenExp = cookies["user"].tokenExp;
    let compareDate = new Date(tokenExp);
    let user = cookies["user"];
    compareDate.setMinutes(compareDate.getMinutes() - 1);

    if (compareDate.getTime() >= Date.now()) {
      const token = user.token;
      return {token: token, status: StatusCodes.OK};
    }

    const refreshToken = cookies["userRt"].refreshToken;

    //TODO manage if the refresh token is expired too.
    let newTokenResp = await pricePointAuth.refresh(refreshToken);
    if (newTokenResp) {
      if (newTokenResp.status === StatusCodes.OK) {
        user["token"] = newTokenResp.accessToken;
        user["tokenExp"] = new Date(newTokenResp.expires);
        setCookie("user", user, {path: "/", maxAge: 259200});
        setCookie("userRt", {refreshToken: newTokenResp.refreshToken}, {path: "/", maxAge: 518400});
        return {token: newTokenResp.accessToken, status: StatusCodes.OK}
      }
      else {
        return {status: newTokenResp.status};
      }
    }
    else {
      return {error: true}
    }
  };

  const login = async (username, password) => {
    let response = await pricePointAuth.login(username, password);
    if (response) {
      if (response.status === StatusCodes.OK) {
        setCookie('user', {username: response.username, token: response.token, tokenExp: new Date(response.expires)},
          {path: "/", maxAge: 259200});
        setCookie('userOrgEntities', {orgEntities: response.orgEntities}, {path: "/", maxAge: 259200});
        setCookie('userRoles', {roles: response.roles}, {path: "/", maxAge: 259200});
        setCookie("userRt", {refreshToken: response.refreshToken}, {path: "/", maxAge: 518400});
        console.debug("Response username => " + response.username);
        //Auto select if it has only one org entity.
        if (response.orgEntities && response.orgEntities.length === 1) {
          setSelectedOrgEntity(response.orgEntities[0]);
        }
        return {username: response.username, status: response.status};
      }
      else {
        setCookie('user', {status: response.status}, {path: "/", maxAge: 259200});
        return {status: response.status};
      }
    }
    else {
      return {error: true}
    }
  };

  const changePassword = async (username, password, newpassword) => {
    let response = await pricePointAuth.changePassword(username, password, newpassword);
    if (response) {
      if (response.status === StatusCodes.NO_CONTENT) {
        return {status: response.status};
      }
      else {
        return {status: response.status, errorMessage: response.errorMessage, message: response.message};
      }
    }
  };

  const forgotPassword = async (username) => {
    let response = await pricePointAuth.forgotPassword(username);
    if (response) {
      if (response.status === StatusCodes.OK) {
        return {status: response.status}
      }
      else {
        return {status: response.status, errorMessage: response.errorMessage, message: response.message};
      }
    }
  };

  const resetPassword = async (code, username, password) => {
    let response = await pricePointAuth.resetPassword(code, username, password);
    if (response) {
      if (response.status === StatusCodes.NO_CONTENT) {
        return {status: response.status};
      }
      else {
        return {status: response.status, errorMessage: response.errorMessage, message: response.message};
      }
    }
  };

  const getRoles = () => {
    return cookies["userRoles"].roles;
  };

  const getOrgEntities = () => {
    return cookies["userOrgEntities"].orgEntities;
  };

  const getSelectedOrgEntity = () => {
    return cookies["selectedOrgEnt"];
  };

  const setSelectedOrgEntity = (orgEntity) => {
    setCookie("selectedOrgEnt", orgEntity, {path: '/'});
  };

  const getUser = () =>{
    return cookies["user"];
  }

  const logout = async () => {
    let resp = await pricePointAuth.logout();
    removeCookie("user", {path: "/"});
    removeCookie("userOrgEntities", {path: "/"});
    removeCookie("userRoles", {path: "/"});
    removeCookie("selectedOrgEnt", {path: "/"});
    return resp;
  };

  return {
    user: cookies.user,
    login,
    changePassword,
    forgotPassword,
    resetPassword,
    logout,
    getRoles,
    getToken,
    getOrgEntities,
    getSelectedOrgEntity,
    setSelectedOrgEntity,
    getUser
  };
}

