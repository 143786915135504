import React, {useEffect} from "react";

// react-bootstrap components
import {
  Button,
  Dropdown,
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";
import {useAuth} from "../../use-auth";
import {useTranslation} from "react-i18next";
import NotificationAlert from "react-notification-alert";

const AdminNavbar = () => {
  const auth = useAuth();
  //console.log(auth);
  const {t} = useTranslation();
  const orgEntityNotificationRef = React.useRef(null);

  const orgEntityNotification = () => {
    if (!auth.getSelectedOrgEntity()) {
      const options = {
        place: "tc",
        message: t('orgEntity.notSelected.message'),
        type: "info",
        autoDismiss: "15"
      };
      orgEntityNotificationRef.current.notificationAlert(options);
    }
  };

  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const orgEntityItems = auth.getOrgEntities().map((orgEnt, idx) => {
    return (
      <Dropdown.Item data-testid={orgEnt} key={idx} onClick={() => {
        auth.setSelectedOrgEntity(orgEnt);
      }}>
        {t(orgEnt)}
      </Dropdown.Item>
    );
  });

  React.useEffect(() => {
    orgEntityNotification();
  }, [auth.getSelectedOrgEntity()]);

  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-icon d-none d-lg-block border-white bg-white"
                onClick={() => document.body.classList.toggle("sidebar-mini")}
                data-testid="desktopSupport">
                <i className="fal fa-bars fa-2x" style={{color: "#58595B"}}/>
                {/* <FontAwesomeIcon style={{color: "#58595B"}} icon={faBars} size="2x" alt="login"/>  */}
              </Button>
              <Button
                className="btn-fill btn-icon d-block d-lg-none border-white bg-white"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                }
                }
                data-testid="mobileSupport">
                <i className="fal fa-bars fa-2x" style={{color: "#58595B"}}/>
                {/* <FontAwesomeIcon style={{color: "#58595B"}} icon={faBars} size="2x" alt="login"/>  */}
              </Button>
            </div>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav navbar>
              <Dropdown as={Nav.Item} data-testid="orgEnt">
                <Dropdown.Toggle as={Nav.Link} id="dropdown-414718872" variant="default" data-testid="orgEntToggle">
                  <i className="fal fa-warehouse-alt fa-xs me-1">&nbsp;</i>
                  <span className="ps-3 d-lg-block">
                    {auth.getSelectedOrgEntity() ? t(auth.getSelectedOrgEntity()) : t('select.label')}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu data-testid="orgEntList">
                  {orgEntityItems}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  id="dropdown-41471887333"
                  variant="default">
                  <i className="fal fa-user"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  alignRight
                  aria-labelledby="navbarDropdownMenuLink"
                  style={{right: "-6px", top: "120%"}}>
                  <Dropdown.Item
                    href="https://pricepoint02doc.blob.core.windows.net/doc/guides/User%20Manual%20Rev%201.0.pdf"
                    target="_blank">
                    <i className="fal fa-file-pdf fa-lg" style={{paddingRight: "25px"}}/>
                    User Guide
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-danger"
                    href="/auth/logout">
                    <i className="nc-icon nc-button-power"/>
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="rna-container">
        <NotificationAlert ref={orgEntityNotificationRef}/>
      </div>
    </>
  );
};

export default AdminNavbar;
