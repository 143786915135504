import React from "react";
import {useAuth} from "../../use-auth";
import {Route, Redirect} from "react-router-dom";
/**
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 */
export function PrivateRoute({component: Component, ...rest}) {
    console.debug("Entering protected route...");
    let auth = useAuth();
    if(rest.render !== undefined && rest.render != null){
        console.debug("Entered no render");
        return (<Route {...rest} />);
    }
    else {
        return <Route
            {...rest}
            render={(props) => auth && auth.user && auth.user.username
                ? <Component {...props} />
                : <Redirect to={{pathname: '/auth/login', state: {from: props.location}}}/>}
        />;
    }
}