import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../use-auth';
import { StatusCodes } from 'http-status-codes';

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Nav,
  Container,
  Col,
  InputGroup
} from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey as key } from "@fortawesome/pro-regular-svg-icons";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPasswordDefault(
  //props
  ) {
  const auth = useAuth();
  let query = useQuery();
  let un = "";
  let tk = "";

  if(query.get('un')){
    un = query.get('un');
  }

  if(query.get('tk')){
    tk = query.get('tk');
  }

  const {t} = useTranslation();
  const [code, setCode] = useState(tk);
  const [username, setUsername] = useState(un);
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [style, setStyle] = useState({visibility: "hidden"});
  const [usernameError, setUsernameError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);
  const [displayPassError, setPasswordError] = useState(false);
  const [identicalPassword, setIdenticalPassword] = useState(false);
  const [invalidSend, setInvalidSend] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authProblem, setAuthProblem] = useState(false);
  const [successful, setSuccessful] = useState(false);

  let appName = t('app.name.full');
  let title = t('reset-password.title', {"app_title": appName});

  React.useEffect(() => {
    document.title = title
  }, [title]);

  //const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [cardClasses, setCardClasses] = React.useState("card-hidden");

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  function validateUsername(value) {
    setUsername(value);
    //Regex Pattern
    const regex = /^[a-zA-Z0-9_.-]*$/;
    if (regex.test(value)) {
      setUsernameError(false);
    } 
    else {
      setUsernameError(true);
    }
  }

  const onChangeUsername = (e) => {
    //console.log(e.target.value);
    let trim = e.target.value;
    validateUsername(trim.replace(/ /g, ''));
    setInvalidSend(false);
    setAuthError(false);
  };

  function validatePassword(value) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.])[A-Za-z\d@$!%*?&#.]{8,40}$/;
    if(regex.test(value)){
      return false;
    }
    else{
      return true;
    }
  }

const validatePasswordIdentical = async () => {
  if(password === cpassword){
      return true;
  }
  else{
      return false;
  }
}

  let handleSubmit = async e =>{
    e.preventDefault();
    setAuthError(false);
    const codeTrim = code.replace(/ /g, '');
    const usernameTrim = username.replace(/ /g, '');
    //Unit Test the submit
    /* if(Object.keys(props).length !== 0){
      console.log(props);
      setSuccessful(true);
      props.handleSubmit(codeTrim, usernameTrim, password);
      return;
    } */

    if(codeTrim === "" || usernameTrim === "" || password === "" || cpassword === ""){
      setInvalidSend(true);
      setIdenticalPassword(false);
    }
    else{
      const res = await validatePasswordIdentical();
      if(!displayPassError && res){
        let response = await auth.resetPassword(codeTrim, usernameTrim, password);
        if(response.status === StatusCodes.UNAUTHORIZED){
          setAuthError(true);
          setCPassword('');
          setPassword('');
        }
        else if(response.status === StatusCodes.NO_CONTENT){
          setSuccessful(true);
        }
        else{
          setAuthProblem(true);
          setCPassword('');
          setPassword('');
        }
      }
      else{
        setIdenticalPassword(true);
        setCPassword('');
        setPassword('');
      }
    }
  }

  return (
    <>
      <div
        className="full-page section-image"
        data-color="white"
      >
        <div className="content d-flex align-items-center p-0" style={{backgroundColor: "#fff", minHeight: "calc(100vh - 173px)"}}>
          <Container>
            <div className="row login">
            <Col className="mx-auto" md="4" sm="6">
              <Form className="form" onSubmit={handleSubmit}>
                <Card className={cardClasses + " card-login"}
                      style={{borderRadius: 0}}>
                  <Card.Header>
                    <div className="text-center px-4 mx-auto">
                      <FontAwesomeIcon icon={key} size="5x" alt="login"/>
                    </div>
                    <div className="text-center px-4 py-1 mx-auto">
                      <h3>Reset Password</h3>
                    </div>
                  </Card.Header>
                    <Card.Body>
                      <div className="px-5">
                      {usernameError ? (<div className="alert alert-danger mt-1">{t('username.error')}</div>) : ""}
                      {identicalPassword ? (<div className="alert alert-danger mt-1">{t('form.matchPass')}</div>) : ("")}
                      {displayPassError ? (<div className="alert alert-danger mt-1">{t('form.requirements')}</div>) : ("")}
                      {invalidSend ? (<div className="alert alert-danger mt-1">{t('form.invalid')}</div>) : ("")}
                      {authError ? (<div className="alert alert-danger mt-1" role="alert">{t('auth.unauthorized')}</div>) : ""}
                      {authProblem ? (<div className="alert alert-danger mt-1" role="alert">{t('auth.problem')}</div>) : ""}
                      {successful ? (<div className="alert alert-success mt-1" role="alert">{t('change-password.successful')}</div>) : (
                        <>
                          {
                            (un === "") ? (
                              <Form.Group className="mb-3" >
                                <label htmlFor="username">{t('username.label')}</label>
                                <Form.Control
                                  autoFocus
                                  id="username"
                                  name="username"
                                  placeholder={t('username.placeholder')}
                                  type="text"
                                  isInvalid={(invalidSend && username === "")}
                                  onChange={onChangeUsername}
                                  value={username}
                                ></Form.Control>
                              </Form.Group>
                            ) : (
                              <>
                                <Form.Control
                                  id="username"
                                  name="username"
                                  type="text"
                                  value={username}
                                  hidden
                                  readOnly={true}
                                ></Form.Control>
                              </> 
                            )
                          }
                          {
                              (tk === "") ? (
                                <Form.Group className="mb-3" >
                                    <label htmlFor="code">{t('validation-code.label')}</label>
                                    <Form.Control
                                    autoFocus={(un !== "")}
                                    id="code"
                                    name="code"
                                    placeholder={t('validation-code.placeholder')}
                                    type="text"
                                    isInvalid={(invalidSend && code === "")}
                                    onChange={(e) => {
                                      let trim = e.target.value;
                                      setCode(trim.replace(/ /g, ''));
                                      setInvalidSend(false);
                                      setAuthError(false);
                                    }}
                                    value={code}
                                    ></Form.Control>
                                </Form.Group>
                            ) : (
                                <>
                                  <Form.Control
                                    id="code"
                                    name="code"
                                    placeholder="Enter Validation Code"
                                    type="text"
                                    value={code}
                                    hidden
                                    readOnly={true} 
                                  ></Form.Control>
                                </>  
                            ) 
                          }
                        <Form.Group className="mb-3" style={{position: "relative"}} >
                        <div className="alert messageBox mt-1" style={style}>{t('form.password')}</div>
                          <label htmlFor="password">{t('password.label')}</label>
                          <InputGroup>
                            <Form.Control
                              autoFocus={(un !== "") && (tk !== "")}
                              id="password"
                              name="password"
                              placeholder={t('password.placeholder')}
                              type={passwordShown ? "text" : "password"}
                              isInvalid={invalidSend}
                              onChange={(e) => {
                                let trim = e.target.value;
                                setPassword(trim.replace(/ /g, ''));
                                setPasswordError(validatePassword(trim.replace(/ /g, '')));
                                setInvalidSend(false);
                                setIdenticalPassword(false);
                                setAuthError(false);
                              }}
                              onMouseEnter={(e) => {
                                setStyle({visibility: "visible"})
                              }}
                              onMouseLeave={(e) => {
                                setStyle({visibility: "hidden"})
                              }}
                              value={password}
                              maxLength="40"
                            >
                            </Form.Control>
                            <InputGroup.Text onClick={() => setPasswordShown(!passwordShown)}>
                              {passwordShown ? (<i className="fal fa-eye-slash" />) : (<i className="fal fa-eye" />)}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                          <label htmlFor="cpassword">{t('change-password.cpassword.label')}</label>
                          <InputGroup>
                            <Form.Control
                              id="cpassword"
                              name="cpassword"
                              placeholder={t('change-password.cpassword.placeholder')}
                              type={cpasswordShown ? "text" : "password"}
                              isInvalid={invalidSend}
                              onChange={(e) => {
                                let trim = e.target.value;
                                setCPassword(trim.replace(/ /g, ''));
                                setPasswordError(validatePassword(trim.replace(/ /g, '')));
                                setInvalidSend(false);
                                setIdenticalPassword(false);
                                setAuthError(false);
                              }}
                              value={cpassword}
                              maxLength="40"
                            ></Form.Control>
                            <InputGroup.Text onClick={() => setCPasswordShown(!cpasswordShown)}>
                              {cpasswordShown ? (<i className="fal fa-eye-slash" />) : (<i className="fal fa-eye" />)}
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                        <Button className="btn-wd" type="submit" variant="success" disabled={displayPassError} style={{width: "100%"}}>
                          Submit
                        </Button>
                      </>)
                      }
                      </div>
                    </Card.Body>
                  
                  <Card.Footer className="px-4 text-center">
                      <div className="mx-auto d-inline p" >
                        {successful ? (<>Click <Nav.Link to="/auth/login" as={Link} className="px-0 py-0 d-inline">here</Nav.Link> to return to the Login Page.</>) : (<Nav.Link to="/auth/login" as={Link} className="px-0 py-0">Back to login</Nav.Link>)}
                      </div>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

ResetPasswordDefault.whyDidYouRender = true;

export default ResetPasswordDefault;