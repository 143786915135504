import React, {useEffect} from 'react';
import {useAuth} from "../../use-auth";
import {useHistory} from "react-router-dom";

export function Logout({...logoutProps}){
    const auth = useAuth();
    const history = useHistory();

    const handleLogout = async () => {
        await auth.logout();
    };

    const idle = logoutProps.location.state?.idle && logoutProps.location.state.idle;

    useEffect(() => {
        console.debug("Logout Idle = " + idle);
        handleLogout().then(() => {
           if(idle == true){
               history.push({
                   pathname: "/auth/login",
                   state: {
                       idle: true
                   }
               });
           }
           else{
               history.push("/")
           }
        });
    });

    return <></>;
}