import React from "react";
import { Link, useLocation } from "react-router-dom";

// react-bootstrap components
import {
  Navbar,
  Nav,
  Container,
} from "react-bootstrap";

const AuthNavbar = React.memo((props) => {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const location = useLocation();
  
  return (
    <>
      <Navbar
        className="navbar-transparent navbar-absolute bg-white pt-0"
        expand="lg"
        variant="white"
        style={{borderBottom: "0"}}
      >
        <Container style={{maxWidth: "100%", maxHeight: "100px"}}>
          <div className="navbar-wrapper">
        
            <Nav.Link  to="/admin/home" as={Link} style={{margin: "5px 0px 5px 10px", padding: "0"}}><img src={require("assets/logo/logoPriceScheduler.png").default} style={{width: "75%", height: "75%", maxHeight: "150px"}} alt="pricePoint" /></Nav.Link>
            
          </div>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav navbar>
              <Nav.Item
                className={
                  location.pathname === "/auth/lock-screen-page"
                    ? "active mr-1"
                    : "mr-1"
                }
              >
                <Nav.Link to="/auth/login" as={Link}>
                  <i className="fal fa-sign-in-alt" />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
       </Container>
      </Navbar>
    </>
  );
});

export default AuthNavbar;
//Alternative option for icons
//<i className="fal fa-sign-in-alt" style={{color: "#58595B"}}></i>

/*
<Nav.Item className="mr-1">
                <Nav.Link to="/admin/dashboard" as={Link}>
                  <i className="far fa-home"></i>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={
                  location.pathname === "/auth/register-page"
                    ? "active mr-1"
                    : "mr-1"
                }
              >
                <Nav.Link to="/auth/register-page" as={Link}>
                  <i className="fas fa-search"></i>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={
                  location.pathname === "/auth/login-page"
                    ? "active mr-1"
                    : "mr-1"
                }
              >
                <Nav.Link to="/auth/login-page" as={Link}>
                  <i className="fas fa-user"></i>
                </Nav.Link>
              </Nav.Item>
              */