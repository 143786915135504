import React from 'react';
import AuthLayout from "./layouts/Auth";
import AdminLayout from "./layouts/Admin";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {ProvideAuth} from "./use-auth";
import {PrivateRoute} from "./components/Auth/PrivateRoute";
import {Logout} from "./components/Auth/Logout";
import {CookiesProvider} from "react-cookie";

function App() {
  return (
    <CookiesProvider>
      <ProvideAuth>
        <BrowserRouter>
          <Switch>
            <Route exact path="/auth/logout" render={(props) => <Logout {...props}/>}/>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />}/>
            <PrivateRoute path="/app" component={AdminLayout}/>
            <Redirect from="/" to="/app/adjustment"/>
          </Switch>
        </BrowserRouter>
      </ProvideAuth>
    </CookiesProvider>);
}

export default App;