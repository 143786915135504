import Moment from "moment";

export const FormatDate = (d, pattern) => Moment(d).utc().format(pattern ? pattern : "YYYY-MM-DD");

export const FormatBoolean = (b, pattern, i18nt) => {
    return b === undefined ? '' : b === null ? ' ' :
        pattern === 'YN' ? (b === 1 || b===true || b==="true") ? i18nt('yes.label') : i18nt('no.label') :
            pattern === 'TF' ? (b === 1 || b===true || b==="true") ? i18nt('true.label') : i18nt('false.label') : '';
};
export const BooleanReader = (val, i18nt) => {
    return (val === "true"  || val === 1 || val === i18nt('yes.label') ||  val === i18nt('true.label')) ? true : false;
};
