import React, {useState} from "react";
import {Switch, Route, useHistory} from "react-router-dom";
import {useIdleTimer} from 'react-idle-timer'
import {useTranslation} from "react-i18next";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";

// dinamically create dashboard routes
import routes from "routes.js";

function Admin({...adminProps}) {
  const history = useHistory();

  console.debug(adminProps);

  const handleOnIdle = event => {
    console.debug("Redirecting on idle time.");
    history.push({
      pathname: "/auth/logout/",
      state: {
        idle: true
      }
    });
  };

  // const handleOnActive = event => {
  // };

  const {getRemainingTime, getLastActiveTime} = useIdleTimer({
    timeout: 1000 * 60 * 25,
    onIdle: handleOnIdle,
   // onActive: handleOnActive,
    events: [
      'keydown',
      'wheel',
      'mousewheel',
      'mousedown',
      'touchstart',
      'MSPointerDown'
    ],
    debounce: 500,
    eventsThrottle: 300
  });

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/app") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      }
      else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar routes={routes}/>
        <div className="main-panel">
          <AdminNavbar/>
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <AdminFooter/>
          <div className="close-layer" onClick={() =>
            document.documentElement.classList.toggle("nav-open")
          }
          />
        </div>
      </div>
    </>
  );
}

export default Admin;
