import React, {useState, useEffect, useRef} from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {useAuth} from "../../use-auth";
import {useTranslation} from 'react-i18next';

//For Unit Tests
import dummyData from "../../data/data-test-adjSch-modal.json"
import Select from "react-select";
import {getAdjustmentOptions} from "../../utils/adjustment-data"; //Needs to be commented out running Unit Tests involving this components

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import {AsyncPaginate} from "react-select-async-paginate";
import {getMinMaxDate} from '../../utils/adjustment-data';
import {StatusCodes} from "http-status-codes";

function AdjSchedForm(props) {

  const {t} = useTranslation();
  const auth = useAuth();
  let adjSched = props.adjustmentSchedule;

  const [codeState, setCodeState] = useState(true);
  const [descState, setDescState] = useState(true);
  const [startDate, setStartDate] = useState(true);
  const [endDate, setEndDate] = useState(true);
  const [codeErrorTrim, setCodeErrorTrim] = useState(false);
  const [descErrorTrim, setDescErrorTrim] = useState(false);
  const [styleEndDate, setStyleEndDate] = useState({});
  const [styleStartDate, setStyleStartDate] = useState({});
  const [loading, setLoading] = useState(true);

  const loadOptions = async (search, loadedOptions, {page}) => {
    const size = 20;
    const tokenResp = await auth.getToken();

    if (tokenResp.status != StatusCodes.OK) {
      console.error("Could not get the access token for the resources.");
    }

    const token = tokenResp.token;
    const responseJSON = await getAdjustmentOptions(token, auth.getSelectedOrgEntity(), page, size, search);

    return {
      options: responseJSON.options,
      hasMore: responseJSON.hasMore,
      additional: responseJSON.additional,
    };
  };

  const validationData = (value) => {
    return (value && value.length > 0);
  };

  const validationDate = (value) => {
    //console.log(d.isAfter(yesterday))

    /* if(!d.isAfter(yesterday))
      return false; */
    const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/\d{4}$/;
    if (moment.isMoment(value)) {
      //console.log("hola");
      return regex.test(value.format(t('date.short_format')));
    }
    return regex.test(value);
  };

  //console.log(props.selectedAdjustment.value)

  const CustomOption = ({innerProps, isDisabled, data}) => {
    return !isDisabled ?
      (<div {...innerProps}>{data.label} <span className="text-info">( {data.value} )</span></div>) :
      null;
  };

  let [maxEndDate, setMaxEndDate] = useState(null);
  let [minStartDate, setMinStartDate] = useState(null);

  const isValidDate = (current) => {
    let returnValue;

    if (!maxEndDate && !minStartDate) {
      returnValue = true;
    }
    else if (moment.isMoment(maxEndDate)
      && moment.isMoment(minStartDate)
      && (current !== null && moment.isMoment(current))) {
      //Clone maxEndDate to workaround mutability in moment.js
      returnValue = ((current.isBefore(minStartDate)) || (current.isAfter(moment(maxEndDate).add(1, 'd'))));
    }
    else {
      returnValue = false;
    }
    //console.log("isValidDate returning:"+ returnValue + " for " + current.format("DD-MM-YYYY") + ":" + firstPartialResult + ":" + secondPartialResult);
    return returnValue;
  };

  const appName = t('app.name.full');
  const title = t('adjustment.schedule.title', {"app_title": appName});

  useEffect(() => {
    document.title = title;
  }, []);

  // Update the maxEndDate and minStartDate references the first time the form component is rendered.
  useEffect(() => {
    async function getMinMaxDates() {
      const tokenResp = await auth.getToken();
      if (tokenResp.status != StatusCodes.OK) {
        console.error("Could not get the access token for the resources.");
      }
      const token = tokenResp.token;
      let minStartDateJSON = await getMinMaxDate(token, auth.getSelectedOrgEntity(), "min");
      let maxEndDateJSON = await getMinMaxDate(token, auth.getSelectedOrgEntity(), "max");

      if (minStartDateJSON) {
        setMinStartDate(moment(minStartDateJSON));
      }
      if (maxEndDateJSON) {
        setMaxEndDate(moment(maxEndDateJSON));
      }
    }

    getMinMaxDates().then(() => {
      setLoading(false)
    })
      .catch(error => {
        console.error("Error getMinMaxDates: ", error);
      });
  }, []);

  if (loading) {
    return <h3>Loading...</h3>
  }
  else {
    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="stacked-form">
                <Card.Body>
                  <Form action="#" id='adj-sched-form'>
                    <Row>
                      <Col md={3}>
                        <Form.Group>
                          <label
                            htmlFor="adjustmentSchedule.code">
                            {t('adjustmentSchedules.code.label')}
                          </label>
                          <Form.Control
                            id="adjustmentSchedule.code"
                            name="adjustmentSchedule.code"
                            placeholder={t('adjustmentSchedules.code.placeholder')}
                            type="text"
                            maxLength="50"
                            isInvalid={(!codeState || codeErrorTrim)}
                            value={adjSched.code}
                            onInput={(e) => {
                              props.onFormChange({name: 'code', value: e.target.value});
                              setCodeState(true);
                              setCodeErrorTrim(false);
                            }}
                            autoFocus
                          />
                          {!codeState ?
                            (<label
                              className="error text-danger">
                              {t('required.error', {"name": t('adjustmentSchedules.code.label')})}
                            </label>) :
                            codeErrorTrim ?
                              (<label
                                className="error text-danger">
                                {t('adjustmentSchedules.code.trim')}
                              </label>) :
                              null}
                        </Form.Group>
                      </Col>
                      <Col md={9}>
                        <Form.Group>
                          <label
                            htmlFor="adjustmentSchedule.description">
                            {t('adjustmentSchedules.description.label')}
                          </label>
                          <Form.Control
                            id="adjustmentSchedule.description"
                            name="adjustmentSchedule.description"
                            placeholder={t('adjustmentSchedules.description.placeholder')}
                            type="text"
                            maxLength="200"
                            isInvalid={(!descState || descErrorTrim)}
                            value={adjSched.description}
                            onInput={(e) => {
                              props.onFormChange({name: 'description', value: e.target.value});
                              setDescState(true);
                              setDescErrorTrim(false);
                            }}
                          />
                          {!descState ?
                            (<label
                              className="error text-danger">
                              {t('required.error', {"name": t('adjustmentSchedules.description.label')})}
                            </label>) :
                            descErrorTrim ?
                              (<label
                                className="error text-danger">
                                {t('adjustmentSchedules.description.trim')}
                              </label>) :
                              null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={6}>
                        <Form.Group>
                          <label
                            htmlFor="adjustmentSchedule.startDate">
                            {t('adjustmentSchedules.effectiveDate.label', {count: 10})}
                          </label>
                          <ReactDatetime
                            inputProps={{
                              "data-testid": "startDate",
                              className: "form-control",
                              placeholder: t('adjustmentSchedules.effectiveDate.placeholder'),
                              name: "adjustmentSchedule.startDate",
                              style: styleStartDate,
                              value: adjSched.startDate,
                              readOnly: true
                              // onChange: (e) => {
                              //   const caret = e.target.selectionStart;
                              //   const element = e.target;
                              //   const value = e.target.value;
                              //   const startDate = adjSched.startDate;
                              //   //console.log(value);
                              //   //console.log(startDate);
                              //   if (value.length !== 0 && (value.length === 2 || value.length === 5) &&
                              //     (startDate.length !== undefined && (startDate.length < value.length))) {
                              //     window.requestAnimationFrame(() => {
                              //       element.selectionStart = caret + 1;
                              //       element.selectionEnd = caret + 1;
                              //     });
                              //   }
                              //   else {
                              //     window.requestAnimationFrame(() => {
                              //       element.selectionStart = caret;
                              //       element.selectionEnd = caret;
                              //     })
                              //   }
                              // }
                            }}
                            onChange={(e) => {
                              let value = e;
                              if (!moment.isMoment(e)) {
                                value = e.replace(/ /g, '');
                                value = value.replace(/[^\d\/]/g, '');
                                //console.log(value)
                              }
                              //console.log(value);
                              if (validationDate(value)) {
                                props.addDateTime(value, "startDate", true);
                                setStyleStartDate({});
                                setStartDate(true);
                              }
                              else {
                                props.addDateTime(value, "startDate", false);
                                setStyleStartDate({
                                  border: "1px solid #dc3545"
                                });
                                setStartDate(false);
                              }
                            }}
                            maxLength="10"
                            timeFormat={false}
                            name="adjustmentSchedule.startDate"
                            closeOnSelect="true"
                            isInvalid={!startDate}
                            isValidDate={isValidDate}
                          />
                          {startDate ? null :
                            <label className="error text-danger">
                              Invalid Start Date.
                            </label>}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={6}>
                        <Form.Group>
                          <label
                            htmlFor="adjustmentSchedule.endDate">
                            {t('adjustmentSchedules.expireDate.label', {count: 10})}
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: t('adjustmentSchedules.expireDate.placeholder'),
                              name: "adjustmentSchedule.endDate",
                              style: styleEndDate,
                              value: adjSched.endDate,
                              readOnly: true,
                              "data-testid": "endDate"
                              // onChange: (e) => {
                              //   const caret = e.target.selectionStart;
                              //   const element = e.target;
                              //   const value = e.target.value;
                              //   const endDate = adjSched.endDate;
                              //   //console.log(value);
                              //   //console.log(startDate);
                              //   if (value.length !== 0 && (value.length === 2 || value.length === 5) &&
                              //     (endDate.length !== undefined && (endDate.length < value.length))) {
                              //     window.requestAnimationFrame(() => {
                              //       element.selectionStart = caret + 1;
                              //       element.selectionEnd = caret + 1;
                              //     });
                              //   }
                              //   else {
                              //     window.requestAnimationFrame(() => {
                              //       element.selectionStart = caret;
                              //       element.selectionEnd = caret;
                              //     })
                              //   }
                              // }
                            }}
                            //isValidDate={disablePastDt}
                            //dateFormat="YYYY/MM/DD"
                            timeFormat={false}
                            onChange={(e) => {
                              let value = e;
                              if (!moment.isMoment(e)) {
                                value = e.replace(/ /g, '');
                                value = value.replace(/[^\d\/]/g, '');
                              }
                              if (validationDate(value)) {
                                setEndDate(true);
                                setStyleEndDate({});
                                props.addDateTime(value, "endDate", true);
                              }
                              else {
                                setEndDate(false);
                                setStyleEndDate({
                                  border: "1px solid #dc3545"
                                });
                                props.addDateTime(value, "endDate", false);
                              }
                            }}
                            //value={adjSched.endDate}
                            //onChange={ event => { props.onFormChange(event, 'adjustmentSchedule.endDate')} }
                            //onOpen={ event => { setStartDate(true); setEndDate(true); }}
                            closeOnSelect
                            isValidDate={isValidDate}
                          />
                          {
                            endDate ? null :
                              (<label
                                className="error text-danger">
                                End date must be after start date.
                              </label>)
                          }
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <Button
                    data-testid="submitButton"
                    className="btn-fill float-end"
                    type="submit"
                    variant="success"
                    name="formSubmit"
                    onClick={(e) => {
                      let formIsValid = true;

                      if (!codeState || !validationData(adjSched.code)) {
                        setCodeState(false);
                        setCodeErrorTrim(false);
                        formIsValid = false;
                      }
                      else if (adjSched.code.trim().length === 0) {
                        setCodeErrorTrim(true);
                        formIsValid = false;
                      }
                      else {
                        setCodeErrorTrim(false);
                        setCodeState(true);
                      }

                      if (!descState || !validationData(adjSched.description)) {
                        setDescState(false);
                        setDescErrorTrim(false);
                        formIsValid = false;
                      }
                      else if (adjSched.description.trim().length === 0) {
                        setDescErrorTrim(true);
                        formIsValid = false;
                      }
                      else {
                        setDescState(true);
                      }
                      let startDateMoment = moment(adjSched.startDate, "MM/DD/YYYY");
                      let endDateMoment = moment(adjSched.endDate, "MM/DD/YYYY");
                      //startDateMoment && console.log(`adjSched.startDate: ${adjSched.startDate}`);
                      //endDateMoment && console.log(`adjSched.endDate: ${adjSched.endDate}`);

                      if (!adjSched.startDate) {
                        setStartDate(false);
                        setStyleStartDate({
                          border: "1px solid #dc3545"
                        });
                        formIsValid = false;
                      }
                      else {
                        setStartDate(true);
                      }

                      //Check if endDate is later than startDate
                      if ((startDateMoment != null) &&
                        (endDateMoment != null) &&
                        (startDateMoment.isBefore(endDateMoment)) === false) {
                        //console.log('endDate is not later than startDate.');
                        setEndDate(false);
                        setStyleEndDate({
                          border: "1px solid #dc3545"
                        });
                        formIsValid = false;
                      }
                      else {
                        setEndDate(true);
                      }

                      if (formIsValid) {
                        props.handleFormSubmit(e);
                      }
                      else {
                        return false;
                      }
                    }}>
                    <i className="far fa-check"/>
                  </Button>
                  <Button
                    className="float-start"
                    type="reset"
                    variant="warning"
                    name="formReset"
                    onClick={(e) => {
                      props.resetForm(e);
                      document.getElementById("adj-sched-form").reset();
                      setCodeState(true);
                      setDescState(true);
                      setStartDate(true);
                      setEndDate(true);
                      setDescErrorTrim(false);
                      setCodeErrorTrim(false);
                      setStyleStartDate({});
                      setStyleEndDate({});
                    }}>
                    <i className="far fa-eraser"/>
                  </Button>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Subtitle className="text-info" as="span">
                    {t('adjustmentSchedule.adjustment.add')}
                  </Card.Subtitle>
                  <Form action="#">
                    <Row>
                      <Col md="8" sm="8">
                        <Form.Group data-testid="selectedAdjustment">
                          <label>Adjustments</label>
                          {/* Comment this for Unit Tests */}
                          <AsyncPaginate
                            classNamePrefix="react-select"
                            name="selectedAdjustment"
                            placeholder="Select adjustment"
                            onChange={props.onAdjustmentSelect}
                            components={{Option: CustomOption}}
                            loadOptions={loadOptions}
                            isClearable
                            defaultOptions
                            additional={{
                              page: 0,
                            }}
                            value={props.selectedAdjustment}
                          />
                          {/* <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="selectedAdjustment"
                          value={props.selectedAdjustment}
                          onChange={props.onAdjustmentSelect}
                          placeholder="Select adjustment"
                          options={dummyData}
                        /> */}
                        </Form.Group>
                      </Col>
                      <Col md="2" sm="2">
                        <Form.Group>
                          <div>&nbsp;</div>
                          <Button
                            data-testid="addAdjustment"
                            className="form-control-btn"
                            name="btnAddAdjustment"
                            onClick={(e) =>{
                                props.onFormChange({name: 'addAdjustment'})
                            }}
                            disabled={(props.selectedAdjustment !== null) ? false : true}
                            variant="primary">
                            <i className="fa fa-plus"></i>
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdjSchedForm;