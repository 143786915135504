/*eslint-disable*/
import React, {useEffect} from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useRowSelect
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import {matchSorter} from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import RecordCount from "./RecordCount"

// reactstrap components
import {Container, Row, Col, FormGroup, Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../use-auth";

// Define a default UI for filtering
function DefaultColumnFilter({column: {filterValue, setFilter}}) {
  const {t} = useTranslation();
  return (
    <FormGroup>
      <Input
        value={filterValue || ''}
        placeholder={t('search.placeholder')}
        type="text"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {keys: [(row) => row.values[id]]});
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({
                 columns,
                 data,
                 disableActions,
                 sortByCol,
                 manualSortBy,
                 sortByFn,
                 manualFilters,
                 disablePageSize,
                 hiddenColumns,
                 selectedRowHandler,
                 fetchData,
                 pageCount: controlledPageCount,
                 totalRecords,
                 clearFilters,
                 handleClearFilters,
                 //FUTURE To be used.
                 //loading
               }) {
  const {t} = useTranslation();
  const [numberOfRows, setNumberOfRows] = React.useState({
    value: 5,
    label: "5 rows",
  });
  const [pageSelect, handlePageSelect] = React.useState({
    value: 0,
    label: t('page.label', {idx: 1}),
  });
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ?
            String(rowValue).toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(() => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
    []
  );

  if (sortByCol === undefined) {
    let firstColId = columns[0].id;
    sortByCol = React.useMemo(() => {
      return [{id: firstColId}];
    }, []);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    //selectedFlatRows,
    state,
    //visibleColumns,
    nextPage,
    pageOptions,
    //pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    toggleAllRowsSelected,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      filters
      //selectedRowIds
    },
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      disableMultiSort: true,
      initialState: {
        pageSize: 5,
        pageIndex: 0,
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
        sortBy: sortByCol ? sortByCol : []
      },
      autoResetPage: false,
      manualPagination: fetchData ? true : false,
      manualSortBy: manualSortBy ? true : false,
      manualFilters: manualFilters ? true : false,
      pageCount: controlledPageCount
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
    useRowSelect
  );

  let pageSelectData = Array.apply(null, Array(pageOptions.length)).map((prop, key) => {
    return {
      value: key,
      label: t('page.label', {idx: (key + 1)}),
    };
  });

  let numberOfRowsData = [5, 10, 20, 25, 50, 100];

  if (fetchData) {
    //Fetch data if a fetchData function was passed.
    const debouncedFetchData = useAsyncDebounce(fetchData, 300);
    let dependencies = [fetchData, pageIndex, pageSize];
    let props = {pageSize, pageIndex};
    //console.log(props.pageIndex);

    if (manualSortBy) {
      dependencies.push(sortBy);
      props["sortBy"] = sortBy;
    }

    if (manualFilters) {
      dependencies.push(filters);
      props["filters"] = filters;
      //console.log(props["filters"])
    }

    React.useEffect(() => {
        debouncedFetchData(props);
      },
      dependencies
    );
  }
  else {
    if (manualSortBy && sortByFn) {
      React.useEffect(() => {
        sortByFn({pageIndex, pageSize, sortBy});
      }, [sortBy])
    }
  }

  let containerCSS = "ReactTable -striped -highlight primary-pagination";
  let pageSizeOption = disablePageSize !== true ? (
    <Col md="6" sm="8" xs="12">
      <Select
        className="react-select primary"
        classNamePrefix="react-select"
        name="numberOfRows"
        value={numberOfRows}
        onChange={(value) => {
          setPageSize(value.value);
          setNumberOfRows(value);
        }}
        options={numberOfRowsData.map((prop) => {
          return {
            value: prop,
            label: prop + " rows",
          };
        })}
        placeholder="Choose Rows"
        isSearchable={false}
      />
    </Col>
  ) : "";

  const changePageSelect = (pageIndex) => {
    const opt = pageSelectData.filter((pageOpt) => pageOpt.value == pageIndex);
    handlePageSelect(opt);
  };

  useEffect(() => {
    if(clearFilters === true) {
      setAllFilters([]);
      handleClearFilters(false);
    }
  }, [clearFilters]);

    return (
        <>
            <div className={disableActions === true ? containerCSS + ' -disable-actions' : containerCSS}>
                <div className="pagination-top">
                    <div className="-pagination">
                        <div className="-previous">
                        <button
                            type="button"
                            onClick={() => {
                              previousPage(); 
                              changePageSelect(pageIndex - 1);
                            }}
                            disabled={!canPreviousPage}
                            className="-btn">
                            Previous
                        </button>
                        </div>
                        <div className="-center">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="6" sm="8" xs="12">
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="pageSelect"
                                            value={pageSelect}
                                            onChange={(value) => {
                                                gotoPage(value.value);
                                                handlePageSelect(value);
                                            }}
                                            options={pageSelectData.map((prop, key) => {
                                                return {
                                                    value: key,
                                                    label: "Page " + (key + 1),
                                                };
                                            })}
                                            placeholder="Choose Page"
                                            isSearchable={false}
                                        />
                                    </Col>
                                    {pageSizeOption}
                                </Row>
                            </Container>
                        </div>
                        <div className="-next">
                            <button
                                type="button"
                                onClick={() => {
                                  nextPage(); 
                                  changePageSelect(pageIndex + 1);
                                }}
                                disabled={!canNextPage}
                                className="-btn">
                                Next
                            </button>
                        </div>
                    </div>
                </div>
                <table {...getTableProps()} className="rt-table">
                    <thead className="rt-thead -header">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                            {headerGroup.headers.map((column, key) => (
                                <th
                                    key={key}
                                    className={classnames("rt-th rt-resizable-header", {
                                        "-cursor-pointer": (headerGroup.headers.length - 1 !== key) || disableActions,
                                        "-sort-asc": column.isSorted && !column.isSortedDesc,
                                        "-sort-desc": column.isSorted && column.isSortedDesc,
                                    }, column.addClassName ? ' ' + column.addClassName : '')}>
                                    <div className="rt-resizable-header-content" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                    </div>
                                    {/* Render the columns filter UI */}
                                    <div>
                                        {(headerGroup.headers.length - 1 === key) && !disableActions
                                            ? null
                                            : column.canFilter
                                                ? column.render("Filter")
                                                : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="rt-tbody">
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                onClick={() => {
                                    {/* Only select one row at a time. */
                                    }
                                    toggleAllRowsSelected(false);
                                    row.toggleRowSelected(row.id, true);
                                    if (selectedRowHandler) {
                                        selectedRowHandler(row ? row : null);
                                    }
                                }}
                                {...row.getRowProps()}
                                className={classnames(
                                    "rt-tr",
                                    {" -odd": i % 2 === 0},
                                    {" -even": i % 2 === 1}
                                )}>
                                {
                                    row.cells.map((cell) => {
                                        if(cell.column.id !== "lastUpdated" && cell.column.id !== "startDate" && cell.column.id !== "endDate"){
                                            return (
                                                <td 
                                                    {...cell.getCellProps()} 
                                                    className="rt-td" 
                                                    //data-testid={cell.column.id + " " + cell.row.id}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            ); 
                                        }
                                        return (
                                            <td 
                                                {...cell.getCellProps()} 
                                                className="rt-td" 
                                                style={{textAlign: "center"}} 
                                                //data-testid={cell.column.id + " " + cell.row.id}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })}
                    </tbody>
                    <tfoot>
                    <RecordCount state={state} page={page}
                                 totalRecords={totalRecords ? totalRecords : data ? data.length : 0}/>
                    </tfoot>
                </table>
                {/* <div>
                    <h3 className="text-danger">{printSelectedRow(selectedFlatRows)}</h3>
                </div> */}
                <div className="pagination-bottom"></div>          
            </div>
        </>
  );
}

/* const printSelectedRow = (selectedFlatRows) => {
  return selectedFlatRows && selectedFlatRows.length > 0 ? `Selected code: ${selectedFlatRows[0].values.code} ` : '';
}; */

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
