/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import "./wdyr.js"
import React from 'react';
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-pro/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datetime/css/react-datetime.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.1.0";
import "assets/css/custom.css?v=1.0.0";

import App from './App';
import './i18n';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'

library.add(fal);
library.add(far);

ReactDOM.render( <App/>, document.getElementById("root"));
