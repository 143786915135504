import React, {useState} from "react";
import {useAuth} from "../../use-auth";
import {useTranslation} from "react-i18next";
import {createAdjustment, editAdjustment} from "../../utils/adjustment-data";
import {StatusCodes} from "http-status-codes";
import {Alert, Button, Modal} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PermissionsGate from "../Auth/PermissionGate";
import {SCOPES} from "../../role-permissions";
import AdjustmentDetail from "./AdjustmentDetail";

function AdjustmentDetailModal({data, handleClose, show, dataUpdatedCnt, setDataUpdatedCnt, disableSubmit, create}) {
  const auth = useAuth();
  const {t} = useTranslation();

  const [displayOnly, setDisplayOnly] = useState();
  const [doSubmit, setDoSubmit] = useState(false);
  const [alert, setAlert] = useState(false);
  const [adjustmentMessage, setAdjustmentMessage] = useState('');
  const [adjustmentAlertType, setAdjustmentAlertType] = useState("danger");

  const setInputChanged = () => data.changed[1];

  const postForm = async () => {
    if (displayOnly) {
      //do nothing
    }
    else {
      if (data.validate()) {
        const orgEntity = auth.getSelectedOrgEntity();
        const tokenResp = await auth.getToken();
        if(tokenResp.status != StatusCodes.OK){
          console.error("Could not get the access token for the resources.");
        }
        const token = tokenResp.token;
        const resp = create ? await createAdjustment(token, orgEntity, data.fields) : await editAdjustment(token, orgEntity, data.fields);
        if (resp.status == StatusCodes.NO_CONTENT) {
          setAdjustmentMessage(t('edit.success', {entityName: t('adjustment.label', {count: 1})}));
          setAdjustmentAlertType("success");
          setInputChanged(false);
          setDataUpdatedCnt(dataUpdatedCnt + 1);
        }
        else if (resp.status == StatusCodes.CREATED) {
          setAdjustmentMessage(t('create.success', {entityName: t('adjustment.label', {count: 1})}));
          setAdjustmentAlertType("success");
          setInputChanged(false);
          setDataUpdatedCnt(dataUpdatedCnt + 1);
        }
        else if (resp.status == StatusCodes.UNPROCESSABLE_ENTITY) {
          if (create) {
            if (resp.body && resp.body.EntityExistsResponse) {
              setAdjustmentMessage(t('exists.message',
                {
                  identifier: resp.body.EntityExistsResponse.identifier,
                  entityName: t('adjustment.label', {count: 1})
                }));
              setAdjustmentAlertType("danger");
              const setInvalid = data.fields.code.isInvalid[1];
              const setValid = data.fields.code.isValid[1];
              setInvalid(true);
              setValid(false);
            }
          }
        }
        else {
          setAdjustmentMessage(t(create ? 'create.error' : 'edit.error', {entityName: t('adjustment.label', {count: 1})}));
          setAdjustmentAlertType("danger");
        }
      }
      else {
        setAdjustmentMessage(t(create ? 'create.invalid' : 'edit.invalid', {entityName: t('adjustment.label', {count: 1})}));
        setAdjustmentAlertType("danger");
      }
    }
    setAlert(null);
  };

  const submitForm = () => {
    setDoSubmit(true);
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const AdjustmentAlertPanel = () => {
    if (adjustmentMessage) {
      return (
        <>
          <Alert variant={adjustmentAlertType} className="mb-3" dismissible={true}
                 onClose={() => setAdjustmentMessage(undefined)}>
            {adjustmentMessage}
          </Alert>
        </>
      );
    }

    return <><span></span></>;
  };

  const openWarnEditAlert = () => {
    const adjustmentName = t('adjustment.label', {count: 1});
    setAlert(
      <SweetAlert
        warning
        style={{display: "block"}}
        title={t('save.alert.title')}
        onConfirm={() => postForm()}
        onCancel={() => closeAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText={<FontAwesomeIcon icon={["fal", "check"]}/>}
        cancelBtnText={<FontAwesomeIcon icon={["fal", "times-circle"]}/>}
        showCancel>
        <span>{t('save.alert.message', {entityName: adjustmentName})}</span>
      </SweetAlert>
    );
  };

  const adjustmentTitle = create ? t('create.label', {name: t('adjustment.label', {count: 1})}) : t('adjustment.label', {count: 1});

  //console.debug("Render adjustment modal.");

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" animation={false}>
        <Modal.Header>
          <Modal.Title>{adjustmentTitle}</Modal.Title>
          <button className="btn-close" onClick={(event) => {
            setAdjustmentMessage(undefined);
            handleClose(event);
          }}></button>
        </Modal.Header>
        <Modal.Body>
          <AdjustmentAlertPanel/>
          <PermissionsGate scopes={[SCOPES.canViewOnly]}>
            <AdjustmentDetail
              data={data}
              submitHandler={submitForm}
              displayOnly={true}
              doSubmit={doSubmit}
              setDisplayOnly={setDisplayOnly}/>
          </PermissionsGate>
          <PermissionsGate scopes={[SCOPES.canEdit]}>
            <AdjustmentDetail
              data={data}
              submitHandler={postForm}
              doSubmit={doSubmit}
              validated={data.validated[0]}
              setDisplayOnly={setDisplayOnly}/>
          </PermissionsGate>
        </Modal.Body>
        <PermissionsGate scopes={[SCOPES.canEdit]}>
          <Modal.Footer>
            <div>&nbsp;</div>
            <Button variant="success" disabled={disableSubmit} type="button"
                    onClick={openWarnEditAlert} title={t('save.label')}>
              <FontAwesomeIcon icon={["far", "check"]}/>
            </Button>
          </Modal.Footer>
          {alert}
        </PermissionsGate>
      </Modal>

    </>
  );
}


export default AdjustmentDetailModal;