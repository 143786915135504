import {useTranslation} from "react-i18next";
import React from "react";
import Select from "react-select";

export function ValuesSelectColumnFilter({column: {setFilter, selectFilterOptions, id}}) {
    const {t} = useTranslation();

    const allLabel = `[${t('all.label')}]`;
    selectFilterOptions = [{value: null, label: allLabel}, ...selectFilterOptions];
    // Render a multi-select box
    return (
        <Select classNamePrefix="table-filter-select" options={selectFilterOptions} isClearable
                onChange={(v, a) => {
                    setFilter(v && v.value !== null ? v.value : undefined);
                }}/>
    );
}