export const ROLES = {
    VIEWER: "ROLE_ADJ_USER",
    ADMIN: "ROLE_ADMIN",
    MANAGER: "ROLE_ADJ_MANAGER"
};

{
    /* Note: can-xxxxx scopes works both for adjustments and adjustment schedules*/
}

export const SCOPES = {
    canCreate: "can-create",
    canEdit: "can-edit",
    canDelete: "can-delete",
    canView: "can-view",
    canViewOnly: 'can-view-only'
};

export const PERMISSIONS = {
    [ROLES.VIEWER]: [SCOPES.canView, SCOPES.canViewOnly],
    [ROLES.ADMIN]: [SCOPES.canView, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canCreate],
    [ROLES.MANAGER]: [SCOPES.canView, SCOPES.canEdit, SCOPES.canDelete, SCOPES.canCreate],
};
