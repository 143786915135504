import React, {useState, useEffect, useCallback, useRef} from "react";
import AdjustmentSchedulesTable from '../../components/AdjSchedules/AdjustmentSchedulesTable.jsx';
import {useTranslation} from 'react-i18next';
import {getAdjustmentScheduleList} from '../../utils/adjustment-data';
import AdjSchedModal from "../../components/AdjSchedules/AdjSchedModal";
import AdjSchedViewModal from "../../views/AdjustmentSchedules/AdjSchedViewModal";
import AdjSchedEditModal from "../../views/AdjustmentSchedules/AdjSchedEditModal";
import NotificationAlert from "react-notification-alert";
import {getAdjustmentSchedule} from 'utils/adjustment-data';
import LoadingOverlay from 'react-loading-overlay'
import FadeLoader from 'react-spinners/FadeLoader'

// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import PermissionsGate from '../../components/Auth/PermissionGate'
import {SCOPES} from '../../role-permissions'
import AdjustmentDetail from '../../components/Adjustments/AdjustmentDetail.js';
import {useAuth} from "../../use-auth";
import {couldStartTrivia} from "typescript";
import {StatusCodes} from "http-status-codes/build/es";

function AdjustmentSchedulesPage(
  //props
) {
  const notificationAlertRef = useRef(null);

  const notify = (type, place, message) => {
    var options = {};
    options = {
      place: place,
      allowOutsideClick: false,
      allowEscapeKey: false,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 8,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  const {t} = useTranslation();
  const auth = useAuth();

  //Pagination
  const [data, setData] = useState([]);  //The data with schedules (rendered in table).
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataUpdatedCnt, setDataUpdatedCnt] = useState(0); // Controls the rendering of the table.
  const [adjSch, setAdjSch] = useState(null);  //The selected schedule (rendered in modals).
  const [scheduleDetails, setScheduleDetails] = useState([]); //The details of the selected schedule(rendered in modals).

  //Modal display control
  const [showAdjustmentScheduleDetail, setShowAdjustmentScheduleDetail] = useState(false);
  const [showAdjustmentScheduleViewDetail, setShowAdjustmentScheduleViewDetail] = useState(false);
  const [showAdjustmentScheduleEditDetail, setShowAdjustmentScheduleEditDetail] = useState(false);

  const appName = t('app.name.full');
  const title = t('adjustmentSchedules.title', {"app_title": appName});

  useEffect(() => {
    document.title = title;
  }, [title]);

  //const fetchIdRef = React.useRef(0);

  //This fetchData is a simulation
  /* const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Set the loading state
      setLoading(true)

      // We'll even set a delay to simulate a server here
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex
          const endRow = startRow + pageSize
          setData(props.data.slice(startRow, endRow))

          // Your server could send back total page count.
          // For now we'll just fake it, too
          setPageCount(Math.ceil(props.data.length / pageSize))

          setTotalRecords(props.data.length);
          setLoading(false)
        }
      }, 1000)
    }, []) */

  //Need to commented for Unit Tests
  const fetchData = useCallback(({
                                   pageSize,
                                   pageIndex,
                                   sortBy,
                                   filters
                                 }) => {
    setLoading(true);
    //const fetchId = ++fetchIdRef.current;
    if (!sortBy) {
      sortBy = [{id: "lastUpdated", desc: "true"}];
    }

    auth.getToken().then((tokenResp) => {
      if (tokenResp.status != StatusCodes.OK) {
        console.error("Could not get the access token for the resources.");
      }
      const token = tokenResp.token;

      getAdjustmentScheduleList(
        token,
        auth.getSelectedOrgEntity(),
        pageIndex,
        pageSize,
        sortBy[0],
        filters,
        (dat, pageCount, totalCount) => {
          setData(dat);
          setLoading(false);
          setPageCount(pageCount);
          setTotalRecords(totalCount);
        },
        () => {
          setData([]);
          setLoading(false);
        });
    });
  }, [auth.getSelectedOrgEntity(), dataUpdatedCnt]);


  //Adjustment Schedule object to update with values of the record selected (clicked-on) by the user.
  //It gets updated with new values every time the user clicks on a record, or selects one of the actions in the table.
  // See handleAdjustmentScheduleSelectedRow() function below.

  const adjustmentScheduleDetailData = {
    id: useState(),
    code: useState(),
    description: useState(),
    startDate: useState(),
    endDate: useState()
  };


  //When user clicks on a record in the table, handleAdjustmentScheduleSelectedRow()
  //will update the value of adjustmentScheduleDetailData.id, triggering this hook.
  //This hook will read the schedule and schedule details from the database, and
  //update the local adjSchedule and scheduleDetails state variables, which are then
  //passed as props to the modals for either display or edit.

  useEffect(() => {
      // console.log("useEffect called.  ID is " + adjustmentScheduleDetailData.id[0]);
      if (adjustmentScheduleDetailData.id[0]) {
        auth.getToken().then((tokenResp) => {
          if (tokenResp.status != StatusCodes.OK) {
            console.error("Could not get the access token for the resources.");
          }
          const token = tokenResp.token;
          getAdjustmentSchedule(
            token,
            auth.getSelectedOrgEntity(),
            adjustmentScheduleDetailData.id[0],
            (adjustmentSchedule, scheduleDetails) => {

              const isEmpty = Object.keys(scheduleDetails).length === 0;

              if (!isEmpty) {
                adjustmentSchedule.scheduleDetails = scheduleDetails._embedded.adjustmentScheduleDetails;
                setAdjSch(adjustmentSchedule);
                setScheduleDetails(scheduleDetails._embedded.adjustmentScheduleDetails);
              }
              else {
                adjustmentSchedule.scheduleDetails = [];
                setAdjSch(adjustmentSchedule);
                setScheduleDetails([]);
              }

              adjustmentSchedule['isDirty'] = false;

              // console.log("adjustmentSchedule is : " + JSON.stringify(adjustmentSchedule));
              // console.log("scheduleDetails is : " + JSON.stringify(scheduleDetails));

              setLoading(false);
            },
            () => {
              console.error('getAdjustmentSchedule() returned error.');
              setAdjSch(null);
              setLoading(false);
            });
        });
      } // Close If
    },
    [adjustmentScheduleDetailData.id[0],
      showAdjustmentScheduleViewDetail,
      showAdjustmentScheduleEditDetail]);


  const handleAdjustmentScheduleSelectedRow = (selectedRow) => {
    if (selectedRow) {
      if (selectedRow.values) {
        const rowKeys = Object.keys(selectedRow.values);
        for (const [key, dataState] of Object.entries(adjustmentScheduleDetailData)) {
          const cellValue = selectedRow.values[key];
          console.debug(`cellValue[${key}]=${cellValue}`);
          //  if (rowKeys.indexOf(key) > 0) {
          let setSate = dataState[1];
          setSate(cellValue);
          //  }
        }
        openAdjustmentScheduleViewModal();
      }
      else {
        console.error(`There were no values for the selected row (id = ${selectedRow.id}`);
      }
    }
    else {
      console.error("There was no selected row.");
    }
  };

  // Handle what happens when user clicks on the 'edit' action (Component = AdjustmentSchedulesTable).
  // Will update the local adjustmentScheduleDetailData object (id field) and will trigger a chain reaction:
  // 1) Trigger the useEffect() hook
  // 2) Get the schedule and detail data from the database.
  // 3) Call the function to update showAdjustmentScheduleEditDetail variable to show the edit modal.

  const handleEditAction = (selectedRow) => {
    if (selectedRow) {
      if (selectedRow) {
        setLoading(true);
        const rowKeys = Object.keys(selectedRow);
        for (const [key, dataState] of Object.entries(adjustmentScheduleDetailData)) {
          const cellValue = selectedRow[key];
          console.debug(`cellValue[${key}]=${cellValue}`);
          let setSate = dataState[1];
          setSate(cellValue);
        }
        openAdjustmentScheduleEditModal();
      }
      else {
        console.error(`There were no values for the selected row (id = ${selectedRow.id}`);
      }
    }
    else {
      console.error("There was no selected row.");
    }
  };

  //View (Read-only) modal Control
  const openAdjustmentScheduleViewModal = () => {
    // console.log("Opening view modal. a.s. id is :" + adjustmentScheduleDetailData.id[0]);
    if (showAdjustmentScheduleEditDetail) {
      closeAdjustmentScheduleEditModal();
    }
    if (showAdjustmentScheduleDetail) {
      closeAdjustmentScheduleModal();
    }
    setShowAdjustmentScheduleViewDetail(true);
  };
  const closeAdjustmentScheduleViewModal = () => {
    setShowAdjustmentScheduleViewDetail(false);
    //resetAdjustmentScheduleData();
  };


  // Create new schedule modal control
  const openAdjustmentScheduleModal = () => {
    // console.log("Opening create modal. a.s. id is :" + adjustmentScheduleDetailData.id[0]);
    if (showAdjustmentScheduleEditDetail) {
      closeAdjustmentScheduleEditModal();
    }
    if (showAdjustmentScheduleViewDetail) {
      closeAdjustmentScheduleViewModal();
    }
    setShowAdjustmentScheduleDetail(true);
  };

  const closeAdjustmentScheduleModal = () => {
    setShowAdjustmentScheduleDetail(false);
  };

  // Edit schedule modal control
  const openAdjustmentScheduleEditModal = () => {
    // console.log("Opening edit modal. a.s. id is :" + adjustmentScheduleDetailData.id[0]);
    if (showAdjustmentScheduleViewDetail) {
      closeAdjustmentScheduleViewModal();
    }
    if (showAdjustmentScheduleDetail) {
      closeAdjustmentScheduleModal();
    }
    setShowAdjustmentScheduleEditDetail(true);
  };

  const closeAdjustmentScheduleEditModal = () => {
    setShowAdjustmentScheduleEditDetail(false);
    //resetAdjustmentScheduleData();
  };

  /* When a dialog is closed, or before opening the "create" modal, the attributes in local object      */
  /* adjustmentScheduleDetailData are cleared.                                                          */
  /* This will trigger the useEffect() hook, but no data will be retrieved since the id field is null.  */
  /* TODO maybe make the default values configurable from the server? */
  const resetAdjustmentScheduleData = () => {
    adjustmentScheduleDetailData.id[1]('');
    adjustmentScheduleDetailData.code[1]('');
    adjustmentScheduleDetailData.description[1]('');
    adjustmentScheduleDetailData.startDate[1](null);
    adjustmentScheduleDetailData.endDate[1](null);
  };

  return (
    <>
      <PermissionsGate scopes={[SCOPES.canView]}>
        <Container fluid>
          <NotificationAlert ref={notificationAlertRef}/>
          <Row>
            <Col md="12">
              <h2 className="title"> {t('adjustmentSchedules.label', {count: 10})}</h2>
            </Col>
            <Card>
              <Card.Body>
                <div className="mt-1"></div>
                <LoadingOverlay active={loading} spinner={<FadeLoader/>}>
                  <AdjustmentSchedulesTable
                    data={data}
                    //data={(props.data !== undefined) ? props.data : data}
                    selectedRowHandler={handleAdjustmentScheduleSelectedRow}
                    fetchData={fetchData}
                    loading={loading}
                    setLoading={setLoading}
                    pageCount={pageCount}
                    setDataUpdatedCnt={setDataUpdatedCnt}
                    dataUpdatedCnt={dataUpdatedCnt}
                    totalRecords={totalRecords}
                    handleEditAction={handleEditAction}
                    notify={notify}/>
                </LoadingOverlay>
              </Card.Body>
            </Card>
          </Row>
          <PermissionsGate scopes={[SCOPES.canViewOnly]}>
            <Row className="mb-2">
              <Col md="12">
                <Button
                  variant="primary"
                  className="float-end"
                  style={{backgroundColor: "#447DF7", border: "0"}}
                  disabled
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </Col>
            </Row>
          </PermissionsGate>
          <PermissionsGate scopes={[SCOPES.canCreate]}>
            <Row className="mb-2">
              <Col md="12">
                <Button data-testid="adjSchModalAdd" variant="primary" className="float-end" onClick={() => openAdjustmentScheduleModal()}>
                  <i className="fa fa-plus"></i>
                </Button>
              </Col>
            </Row>
          </PermissionsGate>


          {/* Modals for Create, View and Edit actions.  They can only be rendered when there is data to show.
                     /* The record to display/work on will be passed as props=adjSch,scheduleDetails
                     /* (For the view and edit actions only - the new schedule modal uses its own empty schedule.
                     */}

          <>

            {showAdjustmentScheduleViewDetail &&

            <AdjSchedViewModal
              adjustmentScheduleData={adjustmentScheduleDetailData}
              handleClose={closeAdjustmentScheduleViewModal}
              show={showAdjustmentScheduleViewDetail}
              setDataUpdatedCnt={setDataUpdatedCnt}
              dataUpdatedCnt={dataUpdatedCnt}
              adjSch={adjSch}
              scheduleDetails={scheduleDetails}
              setAdjSch={setAdjSch}
              setScheduleDetails={setScheduleDetails}
              notify={notify}
              notificationAlertRef={notificationAlertRef}/>

            }

            {showAdjustmentScheduleEditDetail &&

            <AdjSchedEditModal
              adjustmentScheduleData={adjustmentScheduleDetailData}
              handleClose={closeAdjustmentScheduleEditModal}
              show={showAdjustmentScheduleEditDetail}
              setDataUpdatedCnt={setDataUpdatedCnt}
              dataUpdatedCnt={dataUpdatedCnt}
              adjustmentSchedule={adjSch}
              scheduleDetails={scheduleDetails}
              setAdjustmentSchedule={setAdjSch}
              setScheduleDetails={setScheduleDetails}
              notify={notify}
            />
            }

            {showAdjustmentScheduleDetail &&

            <AdjSchedModal
              adjustmentScheduleData={adjustmentScheduleDetailData}
              handleClose={closeAdjustmentScheduleModal}
              show={showAdjustmentScheduleDetail}
              setDataUpdatedCnt={setDataUpdatedCnt}
              dataUpdatedCnt={dataUpdatedCnt}
              notify={notify}
              notificationAlertRef={notificationAlertRef}/>

            }
          </>
        </Container>
      </PermissionsGate>
    </>
  );
}

AdjustmentSchedulesPage.whyDidYouRender = true;

export default AdjustmentSchedulesPage;
