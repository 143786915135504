import React, {useState, useRef} from 'react';
import {Modal} from 'react-bootstrap'
import AdjSchedForm from './AdjSchedForm'
import AdjustmentsTable from './AdjustmentsTable'
import NotificationAlert from "react-notification-alert";
import {useTranslation} from 'react-i18next';
//import {parse, stringify, toJSON, fromJSON} from 'flatted';
import moment from 'moment';
import {saveAdjustmentSchedule} from 'utils/adjustment-data';
import {useAuth} from "../../use-auth";
import {StatusCodes} from "http-status-codes";

// react-bootstrap components

const AdjSchedModal = (props) => {
  const {t} = useTranslation();
  const auth = useAuth();

  const [loading, setLoading] = useState(false);

  const [selectedAdjustment, setSelectedAdjustment] = useState(null);
  const [adjustmentSchedule, setAdjustmentSchedule] = useState(() => {
    let adjSched = {
    };
    //If the adjustment id was passed, fetch it. Otherwise it is a new one.
    if (props.id) {
      //adjustmentSchedule = scheduleService.getAdjustmentSchedule (props.id)
    }

    //Enabled for some Unit Tests
    /* if(props.adjSchData !== undefined) {
      adjSched = props.adjSchData;
    } */

    return adjSched;
  });

  const handleFormChange = (params) => {

    let adjustmentsTmp = [];

    // console.log(params)
    // console.log(selectedAdjustment)

    switch (params.name) {
      case 'code' :
        setAdjustmentSchedule({
          ...adjustmentSchedule,
          code: params.value,
          isDirty: true
        });
        break;

      case 'description' :
        setAdjustmentSchedule({
          ...adjustmentSchedule,
          description: params.value,
          isDirty: true
        });
        break;
      case 'addAdjustment' :
        //let adjustment = adjustmentService.getAdjustmentByCode (event.target.value)
        adjustmentsTmp = [];
        if (adjustmentSchedule.adjustments) {
          adjustmentsTmp = adjustmentSchedule.adjustments;
        }
        if (adjustmentsTmp.some((adj) => adj.code === selectedAdjustment.value)) {
          //alert()
          props.notify("primary", "tc", `Adjustment ${selectedAdjustment.value} is already in the schedule.`);
        }
        else {
          adjustmentsTmp.push({
            id: selectedAdjustment.key,
            code: selectedAdjustment.value,
            description: selectedAdjustment.label,
            freeGoodsFlag: true,
            bundleFlag: true
          });
          setAdjustmentSchedule(
            {
              ...adjustmentSchedule,
              adjustments: adjustmentsTmp,
              isDirty: true
            }
          );
        }
        break;
      case 'adjustmentDelete' :
        adjustmentsTmp = [];
        adjustmentsTmp = adjustmentSchedule.adjustments.filter((adj) => {
          return (adj.code !== params.value);
        });
        setAdjustmentSchedule({
          ...adjustmentSchedule,
          adjustments: adjustmentsTmp,
          isDirty: true
        });
        break;
      default: {
      }
    }
  };

  const handleFormOrder = (event, param, order) => {
    //console.log(event.target.name + " " + event.target.value);
    let AdjSch = adjustmentSchedule;
    let beforeIndex = 0;
    let afterIndex = 0;
    let currentIndex = 0;
    let tempAdjSch = {};

    if (AdjSch.adjustments.length === 1) {
      return;
    }

    for (let i = 0; i < AdjSch.adjustments.length; i++) {
      if (AdjSch.adjustments[i].code === param) {
        if (i === 0) {
          afterIndex = i + 1;
        }
        else if (i + 1 === AdjSch.adjustments.length) {
          afterIndex = i;
          currentIndex = i;
          beforeIndex = i - 1;
        }
        else {
          afterIndex = i + 1;
          currentIndex = i;
          beforeIndex = i - 1;
        }
        break;
      }
    }
    //console.log(beforeIndex + " " + currentIndex + " " + afterIndex);

    if (order === "up") {
      if (beforeIndex !== currentIndex) {
        tempAdjSch = AdjSch.adjustments[currentIndex];
        AdjSch.adjustments[currentIndex] = AdjSch.adjustments[beforeIndex];
        AdjSch.adjustments[beforeIndex] = tempAdjSch;
      }
    }
    else if (order === "down") {
      if (afterIndex !== currentIndex) {
        tempAdjSch = AdjSch.adjustments[currentIndex];
        AdjSch.adjustments[currentIndex] = AdjSch.adjustments[afterIndex];
        AdjSch.adjustments[afterIndex] = tempAdjSch;
      }
    }

    setAdjustmentSchedule({
      ...adjustmentSchedule,
      adjustments: AdjSch.adjustments,
      isDirty: true
    });
  };

  const handleFormSubmit = async (event, param) => {
    if (!adjustmentSchedule.adjustments || (adjustmentSchedule.adjustments.length === 0)) {
      props.notify("warning", "tc", ` Please add at least one adjustment before saving.`);
    }
    else {
      const tokenResp = await auth.getToken();
      if (tokenResp.status != StatusCodes.OK) {
        console.error("Could not get the access token for the resources.");
      }
      const token = tokenResp.token;
      saveAdjustmentSchedule(token, auth.getSelectedOrgEntity(), adjustmentSchedule,
        (savedSchedule) => {
          setAdjustmentSchedule({...savedSchedule, isDirty: false});
          props.setDataUpdatedCnt(props.dataUpdatedCnt + 1);
          setLoading(false);
          props.notify("primary", "tc", ` Adjustment Schedule ${adjustmentSchedule.code} created successfully`);
          resetForm(event, param);
          props.handleClose();
        },
        (response) => {
          console.error(JSON.stringify(response.error).replace(/[\\]+/g, ''));
          const responseBodyObj = JSON.parse(JSON.stringify(response.body).replace(/[\\]+/g, '').slice(1, -1));
          setLoading(false);
          (responseBodyObj.EntityExistsResponse) ?
            props.notify("primary", "tc", `Could not save Schedule ${adjustmentSchedule.code}. ${responseBodyObj.EntityExistsResponse.message}`) :
            props.notify("primary", "tc", `Error saving ${adjustmentSchedule.code}.\n ${JSON.stringify(responseBodyObj.message)}`);
        });
    }
  };

  const handleDates = (event, param, passed) => {
    if (moment.isMoment(event)) {
      const d = event;
      switch (param) {
        case 'startDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            startDate: d.format(t('date.short_format')),
            isDirty: true
          });
          break;
        case 'endDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            endDate: d.format(t('date.short_format')),
            isDirty: true
          });
          break;
        default: {
        }
      }
    }
    else {
      const regexDigitsSlash = /^[\d/]{0,10}$/;
      let value = event.replace(/ /g, '');

      if (param === "startDate" && adjustmentSchedule.startDate !== undefined) {
        let length = adjustmentSchedule.startDate.length;
        if ((value.length < length) && (length === 3 || length === 6) && adjustmentSchedule.startDate[length - 1] === '/') {
          value = value.substring(0, value.length - 1);
        }
      }
      else if (param === "endDate" && adjustmentSchedule.endDate !== undefined) {
        let length = adjustmentSchedule.endDate.length;
        if ((value.length < length) && (length === 3 || length === 6) && adjustmentSchedule.endDate[length - 1] === '/') {
          value = value.substring(0, value.length - 1);
        }
      }

      if (!regexDigitsSlash.test(value)) {
        return;
      }

      if (value.match(/^\d{2}$/) !== null) {
        value = value + '/';
      }
      else if (value.match(/^\d{2}\/\d{2}$/) !== null) {
        value = value + '/';
      }

      let splitDate = value.split('/');
      if (value.length === 2 && splitDate[0].length < 2) {
        //console.log(splitDate)
        splitDate[0] = "0" + splitDate[0] + "/";
        value = splitDate[0];
      }
      else if (value.length === 5 && splitDate[1].length < 2) {
        //console.log(splitDate);
        splitDate[0] = splitDate[0] + "/";
        splitDate[1] = "0" + splitDate[1] + "/";
        value = splitDate[0] + splitDate[1];
      }

      switch (param) {
        case 'startDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            startDate: value,
            isDirty: true
          });
          break;
        case 'endDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            endDate: value,
            isDirty: true
          });
          break;
        default: {
        }
      }
    }
  }

  const resetForm = (event, param) => {
    setSelectedAdjustment(null);
    setAdjustmentSchedule({});
    // console.log("After clear: " + adjustmentSchedule.startDate);
  };

  const handleOnHide = (event) => {
    // if (adjustmentSchedule.isDirty) {
    //   props.notify ("warning", "tc", `Please save values or clear form before closing.`);
    // }
    // else {
    //console.log(adjustmentSchedule.code)
    if ((adjustmentSchedule.code !== undefined && adjustmentSchedule.code !== "") ||
      (adjustmentSchedule.description !== undefined && adjustmentSchedule.description !== "") ||
      (adjustmentSchedule.startDate !== undefined && adjustmentSchedule.startDate !== "") ||
      (adjustmentSchedule.endDate !== undefined && adjustmentSchedule.endDate !== "") ||
      (adjustmentSchedule.adjustments !== undefined && adjustmentSchedule.adjustments.length !== 0)) {
      props.notify("warning", "tc", `Please save values or clear form before closing.`);
    }
    else {
      //console.log(adjustmentSchedule);
      setSelectedAdjustment(null);
      setAdjustmentSchedule({});
      props.handleClose(event);
    }
    // }
  };

  return (
    <>
      <div className="rna-container"></div>

      <Modal show={props.show} onHide={handleOnHide} size="xl" animation={false} data-testid="modalCreate">
        <Modal.Header>
          <Modal.Title>New Adjustment Schedule</Modal.Title>
          <button className="btn-close" onClick={handleOnHide}></button>
        </Modal.Header>
        <Modal.Body>
          <AdjSchedForm
            adjustmentSchedule={adjustmentSchedule}
            adjustmentData={props.adjustmentData}
            selectedAdjustment={selectedAdjustment}
            onFormChange={handleFormChange}
            addDateTime={handleDates}
            onAdjustmentSelect={setSelectedAdjustment}
            handleFormSubmit={handleFormSubmit}
            resetForm={resetForm}
          />
          <AdjustmentsTable
            //fetchData={fetchData}
            adjustmentSchedule={adjustmentSchedule}
            onRecordDelete={handleFormChange}
            onChangeOrder={handleFormOrder}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdjSchedModal;