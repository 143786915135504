import React from "react";
import { Link } from "react-router-dom";

// react-bootstrap components
import { Container, Nav } from "react-bootstrap";

const AdminFooter = React.memo((props) => {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu d-flex justify-content-center text-center">
              <li>
                <Nav.Link to="/" as={Link} className="m-0">Principal</Nav.Link>
              </li>
              <li>
                <a className="m-0 nav-link" href="http://thepricepoint.com/" onClick={(e) => e.preventDefault()}>
                  Price Scheduling
                </a>
              </li>
              <li>
                <div className="m-0 nav-link footer-notLink">
                  v1.0.0
                </div>
              </li>
            </ul>
            <p className="copyright text-center m-0">
              © {new Date().getFullYear()}{" "}
              <img src={require("assets/logo/FW-logo-w-f.png").default} alt="Fusionwork Logo" style={{width:"25px"}} className="pb-1" />
              {" "}
              <a href="https://fwpr.com/" className="text-primary">Fusionworks</a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
})

export default AdminFooter;
