import {Trans, useTranslation} from "react-i18next";
import {Card, Col, Form, Row,} from "react-bootstrap";
import React, {useEffect} from "react";
import {FIXED_OPT_CODE_VAL, VARIABLE_OPT_CODE_VAL} from "../../constants";
import {Field} from "../../utils/manage-fields";

function AdjustmentDetail({data, submitHandler, displayOnly, setDisplayOnly, doSubmit, validated}) {
    const {t} = useTranslation();

    const optionalState = data.fields.optional.state[0];
    const adjustmentId = data.fields.id.state[0];
    const optionalCode = data.fields.optionalCode.state[0];

    const handleSubmit = (e) => {
        e.preventDefault();
        submitHandler();
    };

    const ErrorSeparator = (idx) => {
        if (idx > 0) {
            return <br/>;
        } else {
            return <></>;
        }
    };

    const FieldErrors = ({field, name}) => {
        const {t} = useTranslation();

        if (field.errors[0] && field.errors[0].length > 0) {
            return field.errors[0].map((error, idx) =>
                <div key={idx} className="invalid-feedback">
                    <ErrorSeparator idx={idx}/>
                    {t(error, {name: name, value: field.state[0]})}
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        if (doSubmit === true) {
            submitHandler();
        }
    }, [doSubmit]);

    useEffect(() => {
        if (setDisplayOnly) {
            setDisplayOnly(displayOnly);
        }
    }, [displayOnly]);

    console.debug(`Render adjustment detail.`);

    return <>
        <Card>
            <Card.Body>
                <Form id="adjustment-detail-form" onSubmit={handleSubmit} validated={validated} className="form">
                    <Row>
                        <Col md="3">
                            {/*Id (hidden)*/}
                            <Form.Control type="hidden" name="id" id="id" value={adjustmentId}/>
                            {/*Code*/}
                            <Form.Group controlId="code" className="required">
                                <Form.Label className="required text-break">
                                    {t('adjustment.code.label')}
                                </Form.Label>
                                <Field 
                                    type="text" 
                                    maxLength="50" 
                                    required={true} 
                                    asComponent={Form.Control} 
                                    name="code"
                                    displayOnly={adjustmentId}
                                    data={data} 
                                    onChange={data.fields.code.handleChange}
                                    autoFocus
                                />
                                <FieldErrors 
                                    field={data.fields.code} 
                                    name={t('adjustment.code.label')}
                                />
                            </Form.Group>
                        </Col>
                        {/*Description*/}
                        <Col md="6">
                            <Form.Group controlId="description" className="required">
                                <Form.Label className="text-break required">
                                    {t('adjustment.description.label')}
                                </Form.Label>
                                <Field 
                                    type="text" 
                                    maxLength="200" 
                                    displayOnly={displayOnly} 
                                    required={true} 
                                    name="description"
                                    data={data} 
                                    asComponent={Form.Control} 
                                    onChange={data.fields.description.handleChange}
                                />
                                <FieldErrors 
                                    field={data.fields.description} 
                                    name={t('adjustment.description.label')}
                                />
                            </Form.Group>
                        </Col>
                        {/*Enabled*/}
                        <Col md="3">
                            <Form.Group controlId="enabled">
                                <div>&nbsp;</div>
                                <Form.Check 
                                    className="checkbox-inline" 
                                    type="checkbox"
                                >
                                    <Form.Check.Label className="text-break">
                                        <Field type="checkbox" checked={data.fields.enabled.state[0]} name="enabled" data={data}
                                               asComponent={Form.Check.Input} disabled={displayOnly} onChange={data.fields.enabled.handleChange}/>
                                        <span className="form-check-sign"></span>{t('adjustment.enabled.label')}
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {/*Free Goods*/}
                        <Col md="4">
                            <Form.Group controlId="freeGoods">
                                <div>&nbsp;</div>
                                <Form.Check className="checkbox-inline ps-0" type="checkbox">
                                    <Form.Check.Label className="text-break">
                                        <Field type="checkbox" checked={data.fields.freeGoods.state[0]} name="freeGoods"
                                               asComponent={Form.Check.Input} data={data}
                                               disabled={displayOnly} onChange={data.fields.freeGoods.handleChange}/>
                                        <span className="form-check-sign"></span>{t('adjustment.freeGoods.label')}
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Text muted className="d-block">
                                    <Trans i18nKey="adjustment.freeGoods.help" t={t}>
                                        <strong className="font-weight-bold"></strong>
                                    </Trans>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        {/*Percentage*/}
                        <Col md="4">
                            <Form.Group controlId="percentage">
                                <div>&nbsp;</div>
                                <Form.Check className="checkbox-inline ps-0" type="checkbox">
                                    <Form.Check.Label className="text-break">
                                        <Field type="checkbox" checked={data.fields.percentage.state[0]} name="percentage"
                                               asComponent={Form.Check.Input} data={data}
                                               disabled={displayOnly} onChange={data.fields.percentage.handleChange}/>
                                        <span className="form-check-sign"></span>{t('adjustment.percentage.label')}
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Text muted className="d-block">
                                    <Trans i18nKey="adjustment.percentage.help" t={t}>
                                        <strong className="font-weight-bold"></strong>
                                    </Trans>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        {/*Bundle*/}
                        <Col md="4">
                            <Form.Group controlId="bundle">
                                <div>&nbsp;</div>
                                <Form.Check className="checkbox-inline ps-0" type="checkbox">
                                    <Form.Check.Label className="text-break">
                                        <Field type="checkbox" checked={data.fields.bundle.state[0]} name="bundle"
                                               asComponent={Form.Check.Input} data={data}
                                               disabled={displayOnly} onChange={data.fields.bundle.handleChange}/>
                                        <span className="form-check-sign"></span>{t('adjustment.bundle.label')}
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Text muted className="d-block">
                                    <Trans i18nKey="adjustment.bundle.help" t={t}>
                                        <strong className="font-weight-bold"></strong>
                                    </Trans>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {/*Optional*/}
                        <Col md="2">
                            <Form.Group controlId="optional">
                                <div>&nbsp;</div>
                                <Form.Check className="checkbox-inline ps-0" type="checkbox">
                                    <Form.Check.Label className="text-break">
                                        <Form.Check.Input type="checkbox" onChange={data.fields.optional.handleChange}
                                                          disabled={displayOnly} name="optional" checked={optionalState}/>
                                        <span className="form-check-sign"></span>{t('adjustment.optional.label')}
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        {/*Optional Code*/}
                        <Col md="6">
                            <Form.Group>
                                <div>&nbsp;</div>
                                <Form.Check className="form-check-radio radio-inline" disabled={!optionalState || displayOnly}>
                                    <Form.Check.Label>
                                        {/*TODO change the hardcoded value*/}
                                        <Field
                                            type="radio" id="optionalCodeFixed" name="optionalCode"
                                            value={FIXED_OPT_CODE_VAL} data={data}
                                            asComponent={Form.Check.Input}
                                            checked={optionalCode === FIXED_OPT_CODE_VAL}
                                            disabled={!optionalState || displayOnly}
                                            onChange={data.fields.optionalCode.handleChange}/>
                                        <span className="form-check-sign"></span>{t('adjustment.optionalCode.' + FIXED_OPT_CODE_VAL)}
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check-radio radio-inline" disabled={!optionalState || displayOnly}>
                                    <Form.Check.Label>
                                        {/*TODO change the hardcoded value*/}
                                        <Field
                                            type="radio" id="optionalCodeVariable" name="optionalCode"
                                            value={VARIABLE_OPT_CODE_VAL} data={data}
                                            asComponent={Form.Check.Input}
                                            checked={optionalCode === VARIABLE_OPT_CODE_VAL}
                                            disabled={!optionalState || displayOnly}
                                            onChange={data.fields.optionalCode.handleChange}/>
                                        <span className="form-check-sign"></span>{t('adjustment.optionalCode.' + VARIABLE_OPT_CODE_VAL)}
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Control.Feedback type="invalid">
                                    <FieldErrors field={data.fields.optionalCode} name={t('adjustment.optionalCode.label')}/>
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    </>;
}

export default AdjustmentDetail;