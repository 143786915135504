import React from "react";
import TableScrollbar from 'react-table-scrollbar';

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { useTranslation } from 'react-i18next';

//import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function AdjustmentsTable(props) {
  const {t, i18n} = useTranslation();

  let adjustments = []
  if ( props.adjustmentSchedule.adjustments ) {
    adjustments = props.adjustmentSchedule.adjustments;
  }

      // if (adjustments && console.log(`AdjustmentTable>>> : ${adjustments.length} adjustments passed.`))
    
      // adjustments.forEach( adj => {
      //  console.log(`
      //     ${adj.code}
      //     ${adj.description}
      //     ${adj.freeGoodsFlag}
      //     ${adj.bundleFlag}
      // `);
    // })

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h5>Adjustments</h5>
            <Card>
              <Card.Body>
              <TableScrollbar rows={5}>
              <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Change Order</th>
                      <th>Sequence</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Free Goods?</th>
                      <th>Bundle?</th>
                      <th>Optional Code</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    { adjustments.map((adj, i) => (
                      <tr data-testid={"Adjustment " + adj.code} key={i}>
                        <td style={{position: "relative"}}>
                          <Button
                            data-testid={adj.code + " Up"}
                            onClick={(event) => props.onChangeOrder(event, adj.code, "up")}
                            size="sm"
                            className="btn-link remove"
                            name="adjustmentOrder"
                            value={adj.code}
                          >
                            <i className="far fa-angle-up"/>
                          </Button>
                          <Button
                            data-testid={adj.code + " Down"}
                            onClick={(event) => props.onChangeOrder(event, adj.code, "down")}
                            size="sm"
                            className="btn-link remove"
                            name="adjustmentOrder"
                            value={adj.code}
                          >
                            <i className="far fa-angle-down"/>
                          </Button>
                        </td>
                        <td>{i+1}</td>
                        <td>{adj.code}</td>
                        <td>{adj.description}</td>
                        <td>{adj.freeGoodsFlag? "Yes" : "No"}</td>
                        <td>{adj.bundleFlag? "Yes" : "No"}</td>
                        <td>{adj.optionalCode}</td>
                        <td>
                          <Button
                            onClick={(event) => props.onRecordDelete({name: "adjustmentDelete", value: adj.code})}
                            variant="danger"
                            size="sm"
                            className="btn-link remove text-danger"
                            name="adjustmentDelete"
                            value={adj.code}
                          >
                            <i className="fa fa-times" />
                          </Button>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </TableScrollbar>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default AdjustmentsTable;
