import React from "react";
import {Switch, Route} from "react-router-dom";
// react-bootstrap components

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create auth routes
import routes from "routes.js";

function withProps(Component, props) {
  return <Component {...props} />
}

function Auth({...authProps}) {
  const idle = authProps.location.state?.idle && authProps.location.state.idle;

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={(props) =>
              withProps(prop.component, {idle, ...props})
            }
          />
        );
      }
      else {
        return null;
      }
    });
  };
  return (
    <>
      <div className="wrapper wrapper-full-page">
        {/* Navbar */}
        <AuthNavbar/>
        {/* End Navbar */}
        <Switch>{getRoutes(routes)}</Switch>
        <AuthFooter/>
      </div>
    </>
  );
}

//<FixedPlugin />

export default Auth;
