import {StatusCodes} from "http-status-codes";
import Moment from "moment";

export const getAdjustmentScheduleDetails = async (token, orgEntity, adjustmentScheduleId) => {
  let savedScheduleDetails = [];
  const PUT_BASE_PATH = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const EDIT_PATH = "/adj-schedules/";
  const q = `${adjustmentScheduleId}`;
  const url = PUT_BASE_PATH + EDIT_PATH + q + "/details";

  const response = await fetch(url,
    {
      method: 'GET',
      headers: {
        'Content-Type': "application/json",
        'X-Tenant-Id': orgEntity,
        'Authorization': 'Bearer ' + token
      }
    });

  if (response.status >= 200 && response.status <= 299) {
    const jsonResponse = await response.json();
    //console.debug(jsonResponse);
    savedScheduleDetails = Object.keys(jsonResponse).length !== 0 ? jsonResponse._embedded.adjustmentScheduleDetails : [];
  }
  else {
    // Handle errors
    console.debug(response.status, response.statusText);
    savedScheduleDetails = [];
  }

  return savedScheduleDetails;
};

export const getAdjustmentOptions = async (token, orgEntity, selectedPageIndex, selectedPageSize, filter) => {
  const fieldFilters = {"enabled": true};

  return getAdjustments(token, orgEntity, selectedPageIndex, selectedPageSize, filter, fieldFilters).then((resp) => {
    let hasMore = false;
    if (resp.status == StatusCodes.OK) {
      let options = [];

      if (resp.body) {
        if (resp.body._embedded) {
          const objects = resp.body._embedded.adjustments;

          if (objects && objects.length > 0) {

            objects.map((obj) => {
              options.push({
                key: obj.id,
                value: obj.code,
                label: obj.description
              })
            });
          }
          const totalPages = resp.body.page.totalPages;
          const number = resp.body.page.number;
          hasMore = totalPages > 0 && number < totalPages - 1;
        }
      }
      return {
        options: options,
        hasMore: hasMore,
        additional: {
          page: selectedPageIndex + 1
        },
        error: false
      };
    }
    else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: selectedPageIndex
        },
        error: true
      };
    }
  });
};

/**
 * Get adjustments using the /search/ operation instead of the /list/ operation as with getAdjustmentList.
 * @param orgEntity
 * @param selectedPageIndex
 * @param selectedPageSize
 * @param filter
 * @param fieldFilters
 * @returns {Promise<T>}
 */
export const getAdjustments = async (token, orgEntity, selectedPageIndex, selectedPageSize, term, fieldFilters) => {
  const BASE_URL = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const RELATIVE_PATH = "/adjustments/search/";
  let q = `?page=${selectedPageIndex}&size=${selectedPageSize}&sort=code,asc`;
  const URL = BASE_URL + RELATIVE_PATH + q;
  let body = {
    fields: ["code", "description"],
    term: "*"
  };

  if (term !== undefined && term !== null && typeof term === 'string' && term.trim() !== '') {
    body.term = term;
  }

  if (fieldFilters !== undefined && fieldFilters !== null && Object.keys(fieldFilters).length !== 0) {
    body['fieldFilter'] = fieldFilters;
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'X-Tenant-Id': orgEntity,
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(body)
  };

  return fetch(URL, requestOptions).then((resp) => {
    const contentType = resp.headers.get("content-type");
    if (contentType != null) {
      return resp.json().then(data => {
        return {status: resp.status, body: data};
      })
    }
    else {
      return {status: resp.status};
    }
  });
};

export const getAdjustmentList = (token, orgEntity, selectedPageIndex, selectedPageSize, sortyBy, filters, callBack, errorCallBack) => {
  //https://github.com/traverson/traverson/
  let traverson = require("traverson");

  const BASE_URL = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const RELATIVE_PATH = "/adjustments/";
  let q = `?page=${selectedPageIndex}&size=${selectedPageSize}`;
  if (sortyBy) {
    q += `&sort=${sortyBy.id},${sortyBy.desc ? 'desc' : 'asc'}`;
  }
  const url = BASE_URL + RELATIVE_PATH + q;
  let body = {};
  if (orgEntity) {
    if (filters) {
      filters.forEach(f => {
        body[f.id] = f.value === 'null' ? null : f.value;
      });
    }
    traverson.from(url).json()
      .convertResponseToObject()
      .withRequestOptions({
        headers: {
          "X-Tenant-Id": orgEntity,
          'Authorization': 'Bearer ' + token
        },
      }).post(body, (error, obj) => {
      if (error) {
        console.error(error);
        errorCallBack();
      }
      else {
        let data = obj ? obj._embedded ? obj._embedded.adjustments : [] : [];
        callBack(data, obj.page.totalPages, obj.page.totalElements);
      }
    });
  }
  else {
    callBack([], 0, 0);
  }
};

export const createAdjustment = async (token, orgEntity, adjustmentData) => {
  const POST_BASE_PATH = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const CREATE_PATH = "/adjustments/create";
  const URL = POST_BASE_PATH + CREATE_PATH;

  let data = {};

  for (const [key, field] of Object.entries(adjustmentData)) {
    const dataValue = field.state[0];
    data[key] = typeof dataValue == 'string' ? dataValue.trim() : dataValue;
    if (data[key] === '') {
      data[key] = null;
    }
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'X-Tenant-Id': orgEntity,
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(data)
  };

  return fetch(URL, requestOptions)
    .then((resp) => {
      const contentType = resp.headers.get("content-type");
      if (contentType != null) {
        return resp.json().then(data => {
          return {status: resp.status, body: data};
        })
      }
      else {
        return {status: resp.status};
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
      return {error: true};
    });
};

export const editAdjustment = async (token, orgEntity, adjustmentData) => {
  const POST_BASE_PATH = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const EDIT_PATH = "/adjustments/edit";
  const URL = POST_BASE_PATH + EDIT_PATH;

  let data = {};

  for (const [key, field] of Object.entries(adjustmentData)) {
    const dataValue = field.state[0];
    data[key] = typeof dataValue == 'string' ? dataValue.trim() : dataValue;
  }

  data['orgEntityCode'] = orgEntity;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': "application/json",
      'X-Tenant-Id': orgEntity,
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(data)
  };
  return fetch(URL, requestOptions)
    .then((resp) => {
      return {status: resp.status}
    })
    .catch(error => {
      console.error('There was an error!', error);
      return {error: true};
    });
};

// Gets a single adjustment
export const getAdjustment = (token, orgEntity, id, callBack, errorCallBack) => {
  //https://github.com/traverson/traverson/
  let traverson = require("traverson");
  const baseURL = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const relativePath = "/adjustments/";
  const q = `${id}`;
  const url = baseURL + relativePath + q;

  traverson
    .from(url)
    .json()
    .withRequestOptions({
      method: "GET",
      headers: {
        "X-Tenant-Id": orgEntity,
        'Authorization': 'Bearer ' + token
      },
    })
    .getResource((error, adjustment) => {
      if (error) {
        console.error(`getAdjustment: Error while fetching adjustment id ${q}.`);
        errorCallBack();
      }
      else {
        callBack(adjustment);
      }
    });
};

export const getAdjustmentScheduleList = (token,
                                          orgEntity,
                                          selectedPageIndex,
                                          selectedPageSize,
                                          sortBy,
                                          filters,
                                          callBack,
                                          errorCallBack) => {
  //https://github.com/traverson/traverson/

  let traverson = require("traverson");

  const baseURL = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const relativePath = "/adj-schedules/";
  let q = `?page=${selectedPageIndex}&size=${selectedPageSize}`;
  if (sortBy) {
    q += `&sort=${sortBy.id},${sortBy.desc ? 'desc' : 'asc'}`;
  }
  const url = baseURL + relativePath + q;
  let body = {};
  if (orgEntity) {
    if (filters) {
      filters.forEach(filter => {
        //debugger;
        //Force value for filter when user uses single character
        if (filter.id === 'activeSchedule' && (filter.value.charAt(0) === 'Y' || filter.value.charAt(0) === 'y')) {
          filter.value = "Yes";
        }
        else {
          filter.value = "No";
        }
        body[filter.id] = filter.value === 'null' ? null : filter.value;
      });
    }

    traverson.from(url).json()
      .convertResponseToObject()
      .withRequestOptions({
        headers: {
          "X-Tenant-Id": orgEntity,
          'Authorization': 'Bearer ' + token
        },
      })
      .post(body, (error, obj) => {
        if (error) {
          console.error(`getAdjustmentScheduleList: Error while fetching data from ${url}`);
          errorCallBack();
        }
        else {
//debugger;
          let data = obj ? obj._embedded ? obj._embedded.adjustmentSchedules : [] : [];
          callBack(data, obj.page.totalPages, obj.page.totalElements);
        }
      });
  }
  else { //No orgEntity passed
    callBack([], 0, 0);
  }
};

export const getAdjustmentSchedule = (token, orgEntity, id, callBack, errorCallBack) => {
  //https://github.com/traverson/traverson/
  let traverson = require("traverson");
  const baseURL = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const relativePath = "/adj-schedules/";
  const q = `${id}`;
  const url = baseURL + relativePath + q;

  traverson.from(url).json()
    .withRequestOptions({
      method: "GET",
      headers: {
        "X-Tenant-Id": orgEntity,
        'Authorization': 'Bearer ' + token
      },
    })
    .getResource((error, adjustmentSchedule, traversal) => {
      if (error) {
        console.error(`getAdjustmentSchedule: Error while fetching adjustment schedule id ${q}.`);
        errorCallBack();
      }
      traversal
        .continue()
        .follow('$._links.scheduleDetails.href')
        .getResource((error, scheduleDetails) => {
          if (error) {
            console.error(`getAdjustmentSchedule: Error while fetching details for schedule id ${q}.`);
            errorCallBack();
          }
          else {
            callBack(adjustmentSchedule, scheduleDetails);
          }
        })

    });
};

export const saveAdjustmentSchedule = (token, orgEntity, adjustmentSchedule, callBack, errorCallBack) => {
  //https://github.com/traverson/traverson/
  let traverson = require("traverson");
  const baseURL = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const relativePath = "/adj-schedules/create";
  const url = baseURL + relativePath;

  let newSchedule = {};
  let scheduleDetails = [];

  if (adjustmentSchedule.id != null) {
    newSchedule['id'] = adjustmentSchedule.id;
  }
  newSchedule['orgEntityCode'] = orgEntity;
  newSchedule['code'] = adjustmentSchedule.code.trim();
  newSchedule['description'] = adjustmentSchedule.description.trim();
  newSchedule['startDate'] = Moment.utc(new Date(adjustmentSchedule.startDate)).format('YYYY-MM-DD');
  newSchedule['endDate'] = Moment.utc(new Date(adjustmentSchedule.endDate)).format('YYYY-MM-DD');

  adjustmentSchedule.adjustments.forEach((adjustmentDetail, index) => {
    scheduleDetails.push({
      orgEntityCode: orgEntity,
      adjustmentId: adjustmentDetail.id,
      seq: index + 1
    });
  });

  newSchedule['adjustmentScheduleDetails'] = scheduleDetails;

  traverson.from(url)
    .json()
    .withRequestOptions({
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "X-Tenant-Id": orgEntity,
        'Authorization': 'Bearer ' + token
      },
    })
    .post(newSchedule, (error, response) => {

        if (error || !response.ok) {
          console.error(`saveAdjustmentSchedule: Error while invoking ${url}`);
          errorCallBack(response);
        }
        else {
          callBack(response);
        }
      }
    );

};

export const deleteAdjustmentSchedule = async (token, orgEntity, id) => {
  const POST_BASE_PATH = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const EDIT_PATH = `/adj-schedules/${id}`;
  const URL = POST_BASE_PATH + EDIT_PATH;

  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': "application/json",
      'X-Tenant-Id': orgEntity,
      'Authorization': 'Bearer ' + token
    }
  };
  return fetch(URL, requestOptions)
    .then((resp) => {
      return (resp)
    })
    .catch(error => {
      return (error);
    });
};

export const editAdjustmentSchedule = async (token, orgEntity, id, adjustmentSchedule) => {
//debugger;
  const PUT_BASE_PATH = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const EDIT_PATH = "/adj-schedules/";
  const q = `${id}`;
  const url = PUT_BASE_PATH + EDIT_PATH + q;
  //debugger;
  delete adjustmentSchedule['_links'];
  delete adjustmentSchedule['isDirty'];
  delete adjustmentSchedule.adjustmentScheduleDetails['_links'];
  adjustmentSchedule['orgEntityCode'] = orgEntity;
  adjustmentSchedule['startDate'] = Moment.utc(new Date(adjustmentSchedule.startDate)).format('YYYY-MM-DD');
  adjustmentSchedule['endDate'] = Moment.utc(new Date(adjustmentSchedule.endDate)).format('YYYY-MM-DD');
  adjustmentSchedule['code'] = adjustmentSchedule['code'].trim();
  adjustmentSchedule['description'] = adjustmentSchedule['description'].trim();

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': "application/json",
      'X-Tenant-Id': orgEntity,
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(adjustmentSchedule)
  };

  //fetch() will only reject a promise if the user is offline, or some unlikely networking error occurs, such a DNS lookup failure.
  return fetch(url, requestOptions)
    .then(resp => {
      return (resp);
    })
    .catch(error => {
      return (error);
    });
};

// Gets a single adjustment
export const getMinMaxDate = async (token, orgEntity, minOrMax) => {
  const baseURL = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const resource = (minOrMax === "max") ? "maxEndDate" : "minStartDate";
  const resourceCollection = "/adj-schedules/";
  const url = baseURL + resourceCollection + resource;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': "application/json",
      'X-Tenant-Id': orgEntity,
      'Authorization': 'Bearer ' + token
    }
  };

  return await fetch(url, requestOptions)
    .then((resp) => {
      if (resp.status == StatusCodes.OK) {
        return resp.json();
      }
      else {
        throw new Error("Could not get " + resource + ". Status " + resp.status);
      }
    })
    .then((jsonResp) => {
      return jsonResp[resource];
    })
    .catch(error => {
      console.error("Error getting min/max date: ", error);
    });
};

export const saveScheduleHistory = async (token, orgEntity, actionCode, id) => {
  //debugger;
  const PUT_BASE_PATH = process.env.REACT_APP_BASE_PRICING_SERVICES_URL;
  const EDIT_PATH = `/adj-schedules/history/${actionCode}/`;
  const q = `${id}`;
  const url = PUT_BASE_PATH + EDIT_PATH + q;

  const requestOptions = {
    method: 'GET',
    headers: {
      'X-Tenant-Id': orgEntity,
      'Authorization': 'Bearer ' + token
    }
  };

  return fetch(url, requestOptions)
    .then(resp => {
      return (resp);
    })
    .catch(error => {
      return (error);
    });
};