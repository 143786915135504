import React, {useState, useRef, useEffect} from 'react';
import {Modal} from 'react-bootstrap'
import AdjSchedEditForm from '../../components/AdjSchedules/AdjSchedEditForm';
import AdjustmentsEditTable from '../../components/AdjSchedules/AdjustmentsEditTable';
import {getAdjustment, editAdjustmentSchedule, saveScheduleHistory} from '../../utils/adjustment-data';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {getAdjustmentScheduleDetails} from 'utils/adjustment-data';
import {useAuth} from "../../use-auth";
import {StatusCodes} from "http-status-codes";

const AdjSchedEditModal = ({
                             adjustmentScheduleData,
                             handleClose,
                             show,
                             setDataUpdatedCnt,
                             dataUpdatedCnt,
                             adjustmentSchedule,
                             scheduleDetails,
                             setAdjustmentSchedule,
                             notify
                           }) => {
  const {t} = useTranslation();
  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [selectedAdjustment, setSelectedAdjustment] = useState({});
  const [fullAdjustmentObject, setFullAdjustmentObject] = useState(null);

  //This hook fetches the full adjustment record whenever the user selects a new Adjustment from the <Select> element.
  useEffect(() => {
    if (selectedAdjustment.key) {
      auth.getToken().then((tokenResp) => {
        if (tokenResp.status != StatusCodes.OK) {
          console.error("Could not get the access token for the resources.");
          return;
        }
        const token = tokenResp.token;
        getAdjustment(token,
          auth.getSelectedOrgEntity(),
          selectedAdjustment.key,
          (data) => {
            console.debug(`Adjustment found: ${JSON.stringify(data)}`);
            setFullAdjustmentObject(data)
          },
          () => {
            console.error(`Error while fetching single adjustment ${selectedAdjustment.key}`)
          }
        );
      });
    }
  }, [selectedAdjustment]);

  const handleFormChange = (params) => {

    let adjustmentsTmp = [];

    switch (params.name) {
      case 'code' :
        setAdjustmentSchedule({
          ...adjustmentSchedule,
          code: params.value,
          isDirty: true
        });
        break;

      case 'description' :
        setAdjustmentSchedule({
          ...adjustmentSchedule,
          description: params.value,
          isDirty: true
        });
        break;
      case 'btnAddAdjustment' :
        //let adjustment = adjustmentService.getAdjustmentByCode (event.target.value)
        //debugger;
        adjustmentsTmp = [];
        if (adjustmentSchedule.scheduleDetails) {
          adjustmentsTmp = adjustmentSchedule.scheduleDetails;
        }
        if (adjustmentsTmp.some((detailRecord) => detailRecord.adjustment.code === selectedAdjustment.value)) {
          notify("primary", "tc", `Adjustment ${selectedAdjustment.value} is already in the schedule.`);
        }
        else {
          //Add the full adjustment object to adjustmentSchedule.scheduleDetails.
          //The adjustment should be fetched into the "adjustment" state variable when its value is selected from the list.
          let newAdjustment = {...fullAdjustmentObject};
          let newDetailRecord = {
            seq: adjustmentSchedule.scheduleDetails.length + 1,
            adjustment: newAdjustment
          };
          adjustmentsTmp.push(newDetailRecord);
          adjustmentsTmp.forEach((detailRecord) => {
            JSON.stringify(detailRecord)
          });
          setAdjustmentSchedule(
            {
              ...adjustmentSchedule,
              scheduleDetails: adjustmentsTmp,
              isDirty: true
            }
          );
          //debugger;
        }
        break;
      case 'adjustmentDelete' :
        let adjustmentScheduleTmp = adjustmentSchedule;
        let scheduleDetailsTmp = adjustmentScheduleTmp.scheduleDetails.filter((detailRecord) => {
          return (detailRecord.adjustment.code !== params.value);
        });
        adjustmentScheduleTmp.scheduleDetails = scheduleDetailsTmp;

        setAdjustmentSchedule({
          ...adjustmentScheduleTmp,
          isDirty: true
        });

        //setScheduleDetails( {...scheduleDetailsTmp });
        break;
      default: {
      }
    }

  };

  const handleFormOrder = (event, param, order) => {
    console.debug(event.currentTarget.name + " " + event.currentTarget.value);
    let AdjSch = adjustmentSchedule;
    let tempSeq = 0;
    let beforeIndex = 0;
    let afterIndex = 0;
    let currentIndex = 0;
    let tempAdjSch = {};

    if (AdjSch.scheduleDetails.length === 1) {
      return;
    }

    for (let i = 0; i < AdjSch.scheduleDetails.length; i++) {
      if (AdjSch.scheduleDetails[i].adjustment.code === param) {
        if (i === 0) {
          afterIndex = i + 1;
        }
        else if (i + 1 === AdjSch.scheduleDetails.length) {
          afterIndex = i;
          currentIndex = i;
          beforeIndex = i - 1;
        }
        else {
          afterIndex = i + 1;
          currentIndex = i;
          beforeIndex = i - 1;
        }
        break;
      }
    }

    console.debug("pre:" + beforeIndex + " " + currentIndex + " " + afterIndex);

    if (order === "up") {
      if (beforeIndex !== currentIndex) {
        tempSeq = AdjSch.scheduleDetails[currentIndex].seq;
        AdjSch.scheduleDetails[currentIndex].seq = AdjSch.scheduleDetails[beforeIndex].seq;
        AdjSch.scheduleDetails[beforeIndex].seq = tempSeq;
        tempAdjSch = AdjSch.scheduleDetails[currentIndex];
        AdjSch.scheduleDetails[currentIndex] = AdjSch.scheduleDetails[beforeIndex];
        AdjSch.scheduleDetails[beforeIndex] = tempAdjSch;

      }
    }
    else if (order === "down") {
      if (afterIndex !== currentIndex) {
        tempSeq = AdjSch.scheduleDetails[currentIndex].seq;
        AdjSch.scheduleDetails[currentIndex].seq = AdjSch.scheduleDetails[afterIndex].seq;
        AdjSch.scheduleDetails[afterIndex].seq = tempSeq;
        tempAdjSch = AdjSch.scheduleDetails[currentIndex];
        AdjSch.scheduleDetails[currentIndex] = AdjSch.scheduleDetails[afterIndex];
        AdjSch.scheduleDetails[afterIndex] = tempAdjSch;
      }
    }

    setAdjustmentSchedule({
      ...AdjSch,
      isDirty: true
    });
  }

  const handleFormSubmit = async (event, param) => {

    //debugger;
    if (!adjustmentSchedule.scheduleDetails || (adjustmentSchedule.scheduleDetails.length === 0)) {
      notify("warning", "tc", ` Please add at least one adjustment before saving.`);
    }
    else {
      //This line is needed to send the expected attribute to the service.
      adjustmentSchedule['adjustmentScheduleDetails'] = adjustmentSchedule['scheduleDetails'];
      setLoading(true);

      const tokenResp = await auth.getToken();
      if (tokenResp.status != StatusCodes.OK) {
        console.error("Could not get the access token for the resources.");
      }
      const token = tokenResp.token;

      const saveResp = await saveScheduleHistory(token, auth.getSelectedOrgEntity(), 'E', adjustmentSchedule.id);
      const editResp = await editAdjustmentSchedule(token, auth.getSelectedOrgEntity(), adjustmentSchedule.id, adjustmentSchedule);

      if (editResp.status == StatusCodes.NO_CONTENT) {
        //debugger;
        setLoading(false);
        notify("primary", "tc", ` Adjustment Schedule "${adjustmentSchedule.code}" saved successfully`);
        //resetForm(event, param);
        //handleClose();
        setDataUpdatedCnt(dataUpdatedCnt + 1);
      }
      else {
        //debugger;
        editResp.json()
          .then((errorData) => {
            // console.error(errorData);
            setLoading(false);
            notify("primary", "tc", `Error saving ${adjustmentSchedule.code}.\n ${errorData.message}`);
          });
      }
    }
  };

  const handleDates = (event, param, passed) => {
    console.debug("handle dates event:" + event + ", param: " + param + ", passed: " + passed);
    //debugger;
    if (moment.isMoment(event)) {
      const d = event;
      switch (param) {
        case 'startDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            startDate: d.format(t('date.short_format')),
            isDirty: true
          });
          break;
        case 'endDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            endDate: d.format(t('date.short_format')),
            isDirty: true
          });
          break;
        default: {
        }
      }
    }
    else {
      const regexDigitsSlash = /^[\d/]{0,10}$/;
      let value = event.replace(/ /g, '');

      if (!regexDigitsSlash.test(value)) {
        return;
      }

      if (value.match(/^\d{2}$/) !== null) {
        value = value + '/';
      }
      else if (value.match(/^\d{2}\/\d{2}$/) !== null) {
        value = value + '/';
      }

      let splitDate = value.split('/');
      if (splitDate[0].length < 2 && value.length === 2) {
        //console.log(splitDate)
        splitDate[0] = "0" + splitDate[0] + "/";
        value = splitDate[0];
      }
      else if (value.length === 5 && splitDate[1].length < 2) {
        //console.log(splitDate);
        splitDate[0] = splitDate[0] + "/";
        splitDate[1] = "0" + splitDate[1] + "/";
        value = splitDate[0] + splitDate[1];
      }

      switch (param) {
        case 'startDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            startDate: value,
            isDirty: true
          });
          break;
        case 'endDate':
          setAdjustmentSchedule({
            ...adjustmentSchedule,
            endDate: value,
            isDirty: true
          });
          break;
        default: {
        }
      }
    }
  };

  // Gets called from AdjSchedEditForm component when clicking the reset form button.
  // Since we are editing an existing adjustment schedule, the reset action means to 
  // restore the original values for the record before changes were made. 
  const resetForm = async () => {
    const org = auth.getSelectedOrgEntity();

    setSelectedAdjustment({});
    //debugger;

    const tokenResp = await auth.getToken();
    if (tokenResp.status != StatusCodes.OK) {
      console.error("Could not get the access token for the resources.");
    }
    const token = tokenResp.token;

    const savedScheduleDetails = await getAdjustmentScheduleDetails(token, org, adjustmentSchedule.id);

    setAdjustmentSchedule({
      id: adjustmentSchedule.id,
      code: adjustmentScheduleData.code[0],
      description: adjustmentScheduleData.description[0],
      startDate: adjustmentScheduleData.startDate[0],
      endDate: adjustmentScheduleData.endDate[0],
      scheduleDetails: savedScheduleDetails,
      isDirty: false
    });
  };

  if (adjustmentSchedule && scheduleDetails) {
    return (
      <>
        <div className="rna-container">

        </div>
        <Modal show={show}
               onHide={(e) => {
                 adjustmentSchedule.isDirty ?
                   notify("warning", "tc", `Please save values or clear form before closing.`) :
                   handleClose(e);
               }
               }
               size="xl"
               animation={false}>
          <Modal.Header>
            <Modal.Title>Edit Adjustment Schedule</Modal.Title>
            <button className="btn-close"
                    onClick={(event) => {
                      adjustmentSchedule.isDirty ?
                        notify("warning", "tc", `Please save values or clear form before closing.`) :
                        handleClose(event);
                    }
                    }
            ></button>
          </Modal.Header>
          <Modal.Body>
            <AdjSchedEditForm
              adjustmentSchedule={adjustmentSchedule}
              adjustmentData={scheduleDetails}
              selectedAdjustment={selectedAdjustment}
              onFormChange={handleFormChange}
              addDateTime={handleDates}
              onAdjustmentSelect={setSelectedAdjustment}
              handleFormSubmit={handleFormSubmit}
              resetForm={resetForm}
              loading={loading}
            />
            <AdjustmentsEditTable
              //fetchData={fetchData} 
              //adjustmentSchedule={adjustmentSchedule} 
              adjustmentSchedule={adjustmentSchedule}
              scheduleDetails={scheduleDetails}
              onRecordDelete={handleFormChange}
              onChangeOrder={handleFormOrder}
            />
          </Modal.Body>
        </Modal>
      </>
    );

  }
  else {
    return null;
  }
};

export default AdjSchedEditModal;