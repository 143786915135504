import React, {useEffect} from "react";
import debounce from 'debounce-promise';

export const resetField = (field) => {
    console.debug(`reset field with value ${field.state[0]}`);
    const setIsInvalid = field.isInvalid ? field.isInvalid[1] : null;
    const setIsValid = field.isValid ? field.isValid[1] : null;
    const setState = field.state[1];
    const setErrors = field.errors ? field.errors[1] : null;
    const setTouched = field.touched ? field.touched[1] : null;

    console.debug(`===> Touched=${field.touched[0]}`);
    setState(field.initial);
    if (setTouched) setTouched(false);
    if (setIsValid) {
        console.debug(`===> IsValid=${field.isValid[0]}`);
        setIsValid(false);
    }
    if (setIsInvalid) {
        setIsInvalid(false)
    };
    if (setErrors) setErrors([]);
};

export const validateField = (field) => {
    const fieldValue = field.state[0];
    //const field = data.fields[key];
    const isRequired = field.required[0];
    const setIsInvalid = field.isInvalid ? field.isInvalid[1] : null;
    const setIsValid = field.isValid ? field.isValid[1] : null;
    const setErrors = field.errors ? field.errors[1] : null;
    const isFieldValueString = fieldValue !== undefined && fieldValue !== null && typeof fieldValue === 'string';
    let errors = [];
    if (isRequired && (fieldValue === null || fieldValue === undefined || (isFieldValueString && fieldValue.trim() === ''))) {
        if (setIsInvalid) setIsInvalid(true);
        if (setIsValid) setIsValid(false);
        errors.push('required.error');
        if (setErrors) {
            setErrors(errors);
        }
    } else {
        if (setIsValid) {
            if (field.state[0] != field.loadedValue[0]) {
                setIsValid(true);
            } else {
                setIsValid(undefined);
            }
        }
        if (setIsInvalid) setIsInvalid(false);
    }
};


export const Field = (props) => {
    const {name, id, data, asComponent, displayOnly, value, readOnly, ...other} = props;
    const Component = asComponent;
    let additionalProps = {};
    const key = name ? name : id;
    const field = data.fields[key];

    if (field.isInvalid) {
        additionalProps['isInvalid'] = field.isInvalid[0];
    }

    if (field.isValid) {
        additionalProps['isValid'] = field.isValid[0];
    }

    if (field.required) {
        additionalProps['required'] = field.required[0];
    }

    if (id) {
        additionalProps['id'] = id;
    }

    if (name) {
        additionalProps['name'] = name;
    }

    if (readOnly) {
        additionalProps['readOnly'] = readOnly;
    } else {
        additionalProps['readOnly'] = displayOnly ? true : false
    }

    additionalProps['value'] = value ? value : field.state[0];

    const debouncedValidateField = debounce(validateField, 300);
    useEffect(() => {
        if (field.touched[0]) {
            debouncedValidateField(field);
        }
    }, [field.state[0]]);

    return <Component {...other} {...additionalProps}/>
};
