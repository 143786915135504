import {PERMISSIONS} from "../../role-permissions";
import {useAuth} from '../../use-auth'

/**
 *
 * @param permissions
 * @param wantedScopes
 * @returns {boolean} Returns true if the permissions match at least one of the wanted scopes.
 */
const hasPermission = ({permissions, scopes}) => {
    const scopesMap = {};
    let boolCanViewOnly = false;
    let boolCanCreate = false;
    if(permissions){
        if(scopes){
            scopes.forEach((scope) => {
                if(scope == "can-view-only"){
                    boolCanViewOnly = true;
                }
                else if(scope == "can-create"){
                    boolCanCreate = true;
                }
            });
        }
    }

    if (scopes) {
        scopes.forEach((scope) => {
            if(scope !== "can-view-only"){
                scopesMap[scope] = true;
            }
            else{
                scopesMap[scope] = boolCanCreate && boolCanViewOnly ? true : false
            }
        });
    }
    // console.log(scopesMap)

    return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({children, scopes = []}) {
    const auth = useAuth();
    const roles = auth.getRoles();

    const permissions = roles ? [...new Set(roles.map(role => PERMISSIONS[role])).values()].flat() : null;

    const permissionGranted = permissions ? hasPermission({permissions, scopes}) : false;

    // console.log(permissions)

    if (!permissionGranted) return <></>;

    return <>{children}</>;
}
