import React, {useState, useRef, useEffect} from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {useAuth} from "../../use-auth";
import {useTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import {StatusCodes} from "http-status-codes";
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import {getAdjustmentOptions} from "../../utils/adjustment-data";
import {getMinMaxDate} from '../../utils/adjustment-data';
import {AsyncPaginate} from "react-select-async-paginate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function AdjSchedEditForm({
                            adjustmentSchedule,
                            adjustmentData,
                            selectedAdjustment,
                            onFormChange,
                            addDateTime,
                            onAdjustmentSelect,
                            handleFormSubmit,
                            resetForm,
                            loading
                          }) {

  const {t} = useTranslation();
  const auth = useAuth();

  const [codeState, setCodeState] = useState(true);
  const [descState, setDescState] = useState(true);
  const [startDate, setStartDate] = useState(true);
  const [endDate, setEndDate] = useState(true);
  const [styleEndDate, setStyleEndDate] = useState({});
  const [styleStartDate, setStyleStartDate] = useState({});

  const loadOptions = async (search, loadedOptions, {page}) => {
    const size = 20;
    const tokenResp = await auth.getToken();
    if (tokenResp.status != StatusCodes.OK) {
      throw new Error("Could not get the access token for the resources.");
    }
    const token = tokenResp.token;

    const responseJSON = await getAdjustmentOptions(token, auth.getSelectedOrgEntity(), page, size, search);

    return {
      options: responseJSON.options,
      hasMore: responseJSON.hasMore,
      additional: responseJSON.additional,
    };
  };

  let maxEndDateRef = useRef(null);
  let minStartDateRef = useRef(null);
  let [isFirstSchedule, setIsFirstSchedule] = useState(null);
  let [isLastSchedule, setIsLastSchedule] = useState(null);

  const isValidDate = (current, selected) => {
    //debugger;
    if ((adjustmentSchedule.startDate !== null) && (moment.isMoment(moment(adjustmentSchedule.startDate)))
      && (adjustmentSchedule.endDate !== null) && (moment.isMoment(moment(adjustmentSchedule.endDate)))
      && (current !== null) && (moment.isMoment(current))) {
      //console.log("Is valid date called:" + moment(minStartDateRef.current).format("DDMMYYYY") + " " + moment(maxEndDateRef.current).add(1,'d').format("DDMMYYYY"));
      if (isFirstSchedule && isLastSchedule) {
        return true;
        //return !((!current.isBefore(adjustmentSchedule.startDate) && current.isBefore(moment(adjustmentSchedule.endDate).add(1,'d')) ));
      }
      else if (isFirstSchedule) {
        return (current.isBefore(moment(adjustmentSchedule.endDate)));
      }
      else if (isLastSchedule) {
        return (current.isAfter(moment(adjustmentSchedule.startDate).add(1, 'd')));
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  };

  // Update the maxEndDate and minStartDate references the first time the form component is rendered.
  useEffect(() => {
    async function getMinMaxDates() {
      // debugger;
      const tokenResp = await auth.getToken();
      if (tokenResp.status != StatusCodes.OK) {
        throw new Error("Could not get the access token for the resources.");
      }
      const token = tokenResp.token;
      let minStartDateVal = await getMinMaxDate(token, auth.getSelectedOrgEntity(), "min");
      let maxEndDateVal = await getMinMaxDate(token, auth.getSelectedOrgEntity(), "max");
      minStartDateRef.current = minStartDateVal;
      maxEndDateRef.current = maxEndDateVal;

      if (moment(adjustmentSchedule.startDate).format("DDMMYYYY") === moment(minStartDateRef.current).format("DDMMYYYY")) {
        setIsFirstSchedule(true);
      }
      else {
        setIsFirstSchedule(false);
      }

      if (moment(adjustmentSchedule.endDate).format("DDMMYYYY") === moment(maxEndDateRef.current).format("DDMMYYYY")) {
        setIsLastSchedule(true);
      }
      else {
        setIsLastSchedule(false);
      }
    }

    getMinMaxDates().catch(error => {
      console.error("Error getMinMaxDates: ", error);
    });
  }, [adjustmentSchedule.id]);

  const validationData = (value) => {
    return (value && value.length > 0);
  };

  const validationDate = (value) => {
    if (!value) return false;

    //console.log(d.isAfter(yesterday))

    /* if(!d.isAfter(yesterday))
      return false; */
    let d = moment(value);
    const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/\d{4}$/;
    if (moment.isMoment(value)) {
      //console.log("hola");
      return regex.test(value.format(t('date.short_format')));
    }
    else if (moment(value) !== null) {
      //console.log("Entro   =>   ")
      //console.log(d.format("MM-DD-YYYY"));
      return regex.test(d.format(t('date.short_format')))
    }
    return regex.test(d);
  };

  const CustomOption = ({innerProps, isDisabled, data}) => {
    return !isDisabled ?
      (<div {...innerProps}>{data.label} <span className="text-info">( {data.value} )</span></div>) :
      null;
  };

  if ((isLastSchedule === null) || (isFirstSchedule === null)) {
    return <h3>Loading...</h3>
  }
  else {

    return (
      <>
        <Container fluid>
          <Row>
            <Col md="12">
              <Card className="stacked-form">
                <Card.Body>
                  <Form action="#" id='adj-sched-form'>
                    <Row>
                      <Col md={3}>
                        <Form.Group>
                          <label>Code</label>
                          <Form.Control
                            readOnly
                            name="adjustmentSchedule.code"
                            placeholder="Enter code"
                            type="text"
                            maxLength="50"
                            isInvalid={!codeState}
                            value={adjustmentSchedule.code}
                            onInput={(e) => {
                              onFormChange({name: 'code', value: e.target.value});
                              if (validationData(e.target.value)) {
                                setCodeState(true);
                              }
                              else {
                                setCodeState(false);
                              }
                            }}
                            autoFocus
                          />
                          {codeState ? null : (<label className="error text-danger">This field is required.</label>)}
                        </Form.Group>
                      </Col>
                      <Col md={9}>
                        <Form.Group>
                          <label>Description</label>
                          <Form.Control
                            name="adjustmentSchedule.description"
                            placeholder="Enter Description"
                            type="text"
                            maxLength="200"
                            isInvalid={!descState}
                            value={adjustmentSchedule.description}
                            onInput={(e) => {
                              onFormChange({name: 'description', value: e.target.value});
                              if (validationData(e.target.value)) {
                                setDescState(true);
                              }
                              else {
                                setDescState(false);
                              }
                            }}
                          />
                          {descState ? null : (<label className="error text-danger">This field is required.</label>)}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={6}>
                        <Form.Group>
                          <label>{t('adjustmentSchedules.effectiveDate.label', {count: 10})}</label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: t('adjustmentSchedules.effectiveDate.placeholder'),
                              name: "adjustmentSchedule.startDate",
                              style: styleStartDate,
                              value: moment.utc(new Date(adjustmentSchedule.startDate)).format(t('date.short_format')),
                              readOnly: true,
                              disabled: !isFirstSchedule,
                              onChange: (e) => {
                                const caret = e.target.selectionStart;
                                const element = e.target;
                                const value = e.target.value;
                                const startDate = adjustmentSchedule.startDate;
                                if (value.length !== 0 && (value.length === 2 || value.length === 5) &&
                                  (startDate.length !== undefined && (startDate.length < value.length))) {
                                  window.requestAnimationFrame(() => {
                                    element.selectionStart = caret + 1;
                                    element.selectionEnd = caret + 1;
                                  })
                                }
                                else {
                                  window.requestAnimationFrame(() => {
                                    element.selectionStart = caret;
                                    element.selectionEnd = caret;
                                  })
                                }
                              }
                            }}
                            onChange={(e) => {
                              let value = e;
                              if (!moment.isMoment(e)) {
                                value = e.replace(/ /g, '');
                                value = value.replace(/[^\d\/]/g, '');
                              }

                              if (validationDate(value)) {
                                addDateTime(value, "startDate", true);
                                setStyleStartDate({});
                                setStartDate(true);
                              }
                              else {
                                if (value) {
                                  addDateTime(value, "startDate", false);
                                }

                                setStyleStartDate({
                                  border: "1px solid #dc3545"
                                });
                                setStartDate(false);
                              }
                            }}
                            maxLength="10"
                            timeFormat={false}
                            name="adjustmentSchedule.startDate"
                            closeOnSelect="true"
                            isInvalid={!startDate}
                            isValidDate={isValidDate}
                          />
                          {startDate ? null : <label className="error text-danger">Invalid Start Date.</label>}
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={6}>
                        <Form.Group>
                          <label>{t('adjustmentSchedules.expireDate.label', {count: 10})}</label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: t('adjustmentSchedules.expireDate.placeholder'),
                              name: "adjustmentSchedule.endDate",
                              style: styleEndDate,
                              value: moment.utc(new Date(adjustmentSchedule.endDate)).format(t('date.short_format')),
                              readOnly: true,
                              disabled: !isLastSchedule,
                            }}
                            //isValidDate={disablePastDt}
                            //dateFormat="YYYY/MM/DD"
                            timeFormat={false}
                            onChange={(e) => {
                              let value = e;
                              if (!moment.isMoment(e)) {
                                value = e.replace(/ /g, '');
                                value = value.replace(/[^\d\/]/g, '');
                              }
                              if (validationDate(value)) {
                                setEndDate(true);
                                setStyleEndDate({});
                                addDateTime(value, "endDate", true);
                              }
                              else {
                                if (value) {
                                  addDateTime(value, "endDate", false)
                                }

                                setEndDate(false);
                                setStyleEndDate({
                                  border: "1px solid #dc3545"
                                });
                                addDateTime(value, "endDate", false);
                              }
                            }}
                            value={adjustmentSchedule.endDate}
                            closeOnSelect
                            isValidDate={isValidDate}
                          />
                          {endDate ? null : (<label className="error text-danger">End date must be after start date.</label>)}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <LoadingOverlay active={loading} text="Saving..." spinner={<FadeLoader/>}>
                    <Button
                      className="btn-fill float-end"
                      disabled={!adjustmentSchedule.isDirty}
                      type="submit"
                      variant="success"
                      name="formSubmit"
                      onClick={(e) => {
                        let formIsValid = true;

                        if (!codeState || !validationData(adjustmentSchedule.code)) {
                          setCodeState(false);
                          formIsValid = false;
                        }
                        else {
                          setCodeState(true);
                        }

                        if (!descState || !validationData(adjustmentSchedule.description)) {
                          setDescState(false);
                          formIsValid = false;
                        }
                        else {
                          setDescState(true);
                        }

                        let startDateMoment = moment(new Date(adjustmentSchedule.startDate));
                        let endDateMoment = moment(new Date(adjustmentSchedule.endDate));
                        //startDateMoment && console.log(`adjustmentSchedule.startDate: ${adjustmentSchedule.startDate}`);
                        //endDateMoment && console.log(`adjustmentSchedule.endDate: ${adjustmentSchedule.endDate}`);

                        if (!adjustmentSchedule.startDate) {
                          setStartDate(false);
                          setStyleStartDate({
                            border: "1px solid #dc3545"
                          });
                          formIsValid = false;
                        }
                        else {
                          setStartDate(true);
                        }

                        //Check if endDate is later than startDate
                        if ((startDateMoment != null) &&
                          (endDateMoment != null) &&
                          (startDateMoment.isBefore(endDateMoment)) === false) {
                          console.error('Error in schedule dates: endDate is not later than startDate.');
                          setEndDate(false);
                          setStyleEndDate({
                            border: "1px solid #dc3545"
                          });
                          formIsValid = false;
                        }
                        else {
                          setEndDate(true);
                        }

                        if (formIsValid) {
                          handleFormSubmit(e);
                        }
                        else {
                          return false;
                        }
                      }}>
                      <FontAwesomeIcon icon={["far", "check"]}/>
                    </Button>
                  </LoadingOverlay>
                  <Button
                    className="float-start"
                    type="reset"
                    variant="warning"
                    name="formReset"
                    disabled={!adjustmentSchedule.isDirty}
                    onClick={(e) => {
                      document.getElementById("adj-sched-form").reset();
                      resetForm();
                      setCodeState(true);
                      setDescState(true);
                      setStartDate(true);
                      setEndDate(true);
                    }}>
                    <FontAwesomeIcon icon={["far", "eraser"]}/>
                  </Button>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Subtitle className="text-muted" as="span">
                    {t('adjustmentSchedule.adjustment.add')}
                  </Card.Subtitle>
                  <Form action="#">
                    <Row>
                      <Col md="8" sm="8">
                        <Form.Group>
                          <label>Adjustments</label>
                          <AsyncPaginate
                            classNamePrefix="react-select"
                            name="selectedAdjustment"
                            placeholder="Select adjustment"
                            onChange={onAdjustmentSelect}
                            components={{Option: CustomOption}}
                            loadOptions={loadOptions}
                            defaultOptions
                            additional={{
                              page: 0,
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="2" sm="2">
                        <Form.Group>
                          <div>&nbsp;</div>
                          <Button
                            className="form-control-btn"
                            name="btnAddAdjustment"
                            onClick={(e) => {
                                onFormChange({name: 'btnAddAdjustment'})
                                }
                            }
                            disabled={(selectedAdjustment.value && selectedAdjustment.value != "") ? false : true}
                            variant="primary">
                            <i className="fa fa-plus"></i>
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AdjSchedEditForm;