export const internalHandleCheckChange = (name, id, checked, data) => {
    const fieldId = name ? name : id;
    const setCheckState = data.fields[fieldId].state[1];
    const fieldChanged = data.fields[fieldId].touched[0];
    const setFieldChanged = data.fields[fieldId].touched[1];
    const setDataChanged = data.changed[1];
    const dataChanged = data.changed[0];

    setCheckState(checked);
    if(!dataChanged) setDataChanged(true);
    if(!fieldChanged) setFieldChanged(true);
};

// export const handleCheckChange = (data) => {
//     return (event) => {
//         internalHandleCheckChange(event, data);
//     };
// };

export const internalHandleInputChange = (name, id, value, data) => {
    const fieldId = name ? name : id;
    const inputVal = value;
    //Field change value setter
    const setState = data.fields[fieldId].state[1];
    //Flag that field changed setter
    const setFieldChanged = data.fields[fieldId].touched[1];
    const fieldChanged = data.fields[fieldId].touched[0];
    //Flag that the form data changed setter
    const setDataChanged = data.changed[1];
    const dataChanged = data.changed[0];

    setState(inputVal);
    if(!dataChanged) setDataChanged(true);
    if(!fieldChanged) setFieldChanged(true);
};

// export const handleInputChange = (data) => {
//     return (event) => {
//         internalHandleInputChange(event, data);
//     }
// };