import React, {useState, useEffect, useCallback} from "react";
import AdjustmentTable from '../../components/Adjustments/AdjustmentTable.js';
import {useTranslation} from 'react-i18next';
import {getAdjustmentList} from '../../utils/adjustment-data'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import PermissionsGate from '../../components/Auth/PermissionGate'
import {SCOPES} from '../../role-permissions'
import {useAuth} from "../../use-auth";
import AdjustmentDetailModal from "../../components/Adjustments/AdjustmentDetailModal";
import {resetField, validateField} from "../../utils/manage-fields";
import {internalHandleCheckChange, internalHandleInputChange} from "../../utils/common-handlers";
import LoadingOverlay from 'react-loading-overlay';
import FadeLoader from 'react-spinners/FadeLoader';
import {StatusCodes} from "http-status-codes/build/es";

//TODO segments need refactoring into parts.
function AdjustmentPage(
  //props
) {
  //console.log('l')
  const {t} = useTranslation();
  const auth = useAuth();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [dataUpdatedCnt, setDataUpdatedCnt] = useState(0);
  const [showAdjustmentDetail, setShowAdjustmentDetail] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [createAdjustment, setCreateAdjustment] = useState(false);

  //const fetchIdRef = React.useRef(0);

  //This fetchData is a simulation
  /* const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Set the loading state
      setLoading(true)

      // We'll even set a delay to simulate a server here
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex
          const endRow = startRow + pageSize
          setData(props.data.slice(startRow, endRow))

          // Your server could send back total page count.
          // For now we'll just fake it, too
          setPageCount(Math.ceil(props.data.length / pageSize))

          setTotalRecords(props.data.length);
          setLoading(false)
        }
      }, 1000)
    }, []) */

  //Need to be comment for Unit Testing
  const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters}) => {
    setLoading(true);
    //const fetchId = ++fetchIdRef.current;
    if (!sortBy) {
      sortBy = [{id: "lastUpdated", desc: "true"}];
    }

    auth.getToken().then((tokenResp) => {
      if (tokenResp.status !== StatusCodes.OK) {
        console.error("Could not get the access token for the resources.");
        return;
      }
      const token = tokenResp.token;

      getAdjustmentList(token,
        auth.getSelectedOrgEntity(),
        pageIndex,
        pageSize,
        sortBy[0],
        filters,
        (dat, pageCount, totalCount) => {
          setData(dat);
          setLoading(false);
          setPageCount(pageCount);
          setTotalRecords(totalCount);
        },
        () => {
          setData([]);
          setLoading(false);
        });
    });

  }, [auth.getSelectedOrgEntity(), dataUpdatedCnt]);

  const appName = t('app.name.full');
  const title = t('adjustment.title', {"app_title": appName});

  /* TODO maybe make the default values configurable from the server? */

  const detailData = {
    fields: {
      id: {
        initial: '', state: useState(), required: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      },
      code: {
        initial: '', state: useState(), required: useState(true), isValid: useState(), isInvalid: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([]),
      },
      description: {
        initial: '', state: useState(), required: useState(true), isValid: useState(), isInvalid: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      },
      freeGoods: {
        initial: false, state: useState(false), required: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      },
      bundle: {
        initial: false, state: useState(false), required: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      },
      percentage: {
        initial: false, state: useState(false), required: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      },
      enabled: {
        initial: true, state: useState(true), required: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      },
      optional: {
        initial: false, state: useState(false), required: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      },
      optionalCode: {
        initial: '', state: useState(), required: useState(false), isValid: useState(), isInvalid: useState(false),
        loadedValue: useState(), touched: useState(), errors: useState([])
      }
    },
    changed: useState(false),
    validated: useState(false),
    reset: function () {
      for (const [key, fieldData] of Object.entries(this.fields)) {
        resetField(fieldData);
      }
    },
    resetFieldsIsValid: function () {
      for (const [key, fieldData] of Object.entries(this.fields)) {
        const setIsValid = fieldData.isValid ? fieldData.isValid[1] : null;
        if (setIsValid) {
          setIsValid(undefined);
        }
      }
    },
    validate: function () {
      //const setValidated = this.validated[1];
      let isDataValid = true;
      for (const [key, field] of Object.entries(this.fields)) {
        validateField(field);
        if (field.isInvalid && field.isInvalid[0]) {
          isDataValid = false;
        }
      }

      //setValidated(true);
      return isDataValid;
    }
  };

  for (const [key, field] of Object.entries(detailData.fields)) {
    if (['freeGoods', 'enabled', 'bundle', 'percentage'].indexOf(key) >= 0) {
      field['handleChange'] = (event) => {
        internalHandleCheckChange(event.target.name, event.target.id, event.target.checked, detailData);
      }
    }
    else if ('optional' === key) {
      field['handleChange'] = (event) => {
        internalHandleCheckChange(event.target.name, event.target.id, event.target.checked, detailData);
        const setRequired = detailData.fields.optionalCode.required[1];
        const setOptionalCodeVal = detailData.fields.optionalCode.state[1];
        if (event.target.checked) {
          setRequired(true);
        }
        else {
          setRequired(false);
          setOptionalCodeVal(null);
        }
      }
    }
    else {
      field['handleChange'] = (event) => {
        internalHandleInputChange(event.target.name, event.target.id, event.target.value, detailData);
      }
    }
  }

  const hasInvalid = useCallback(function () {
    let hasInvalidResult = false;
    Object.values(Object.values(detailData.fields)).forEach((v) => {
      if (v.isInvalid) {
        if (v.isInvalid[0]) {
          hasInvalidResult = true;
        }
      }
    });
    return hasInvalidResult;
  }, [Object.values(Object.values(detailData.fields)).map((v, i) => {
    if (v.isInvalid) {
      return v.isInvalid[0];
    }
  })]);

  const setOptional = detailData.fields.optional.state[1];
  const handleAdjustmentSelectedRow = (selectedRow) => {
    if (selectedRow) {
      if (selectedRow.values) {
        const rowKeys = Object.keys(selectedRow.values);

        for (const [key, dataObj] of Object.entries(detailData.fields)) {
          // const cellValue = selectedRow.values[key];
          const originalValue = selectedRow.original[key];
          if (rowKeys.indexOf(key) >= 0) {
            let setSate = dataObj.state[1];
            if (key === 'optionalCode') {
              setOptional(originalValue !== null && originalValue !== undefined && originalValue !== '' ? true : false);
              setSate(originalValue);
            }
            else {
              if (!dataObj.loadedValue[0]) {
                const setLoadedValue = dataObj.loadedValue[1];
                setLoadedValue(originalValue);
              }
              setSate(originalValue);
            }
          }
        }
        //Open modal
        openAdjustmentModal();
      }
      else {
        console.error(`There were no values for the selected row (id = ${selectedRow.id}`);
      }
    }
    else {
      console.error("There was no selected row.");
    }
  };

  const openAdjustmentModal = () => {
    setShowAdjustmentDetail(true);
  };

  const closeAdjustmentModal = () => {
    setShowAdjustmentDetail(false);
    setCreateAdjustment(false);
    const setValidated = detailData.validated[1];
    setValidated(false);
    detailData.reset();
  };

  const handleCreate = () => {
    setCreateAdjustment(true);
    //detailData.reset();
    openAdjustmentModal();
  }

  useEffect(() => {
    if (!detailData.changed[0] || hasInvalid()) {
      setDisableSubmit(true);
    }
    else {
      setDisableSubmit(false);
    }
  }, [detailData.changed[0], hasInvalid]);

  useEffect(() => {
    document.title = title;
  }, []);

  console.debug(`Render page.`);
  return (
    <>
      <PermissionsGate scopes={[SCOPES.canView]}>
        <Container fluid>
          <Row>
            <Col md="12">
              <h2 className="title"> {t('adjustment.label', {count: 10})}</h2>
            </Col>
            <Card>
              <Card.Body>
                <div className="mt-1"></div>
                <LoadingOverlay active={loading} spinner={<FadeLoader/>}>
                  <AdjustmentTable
                    data={data}
                    //data={(props.data !== undefined) ? props.data : data}
                    selectedRowHandler={handleAdjustmentSelectedRow}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                    totalRecords={totalRecords}
                  />
                </LoadingOverlay>
              </Card.Body>
            </Card>
          </Row>
          <PermissionsGate scopes={[SCOPES.canViewOnly]}>
            <Row className="mb-2">
              <Col md="12">
                <Button
                  variant="primary"
                  className="float-end"
                  style={{backgroundColor: "#447DF7", border: "0"}}
                  disabled
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </Col>
            </Row>
          </PermissionsGate>
          <PermissionsGate scopes={[SCOPES.canCreate]}>
            <Row className="mb-2">
              <Col md="12">
                <Button
                  variant="primary"
                  className="float-end"
                  onClick={handleCreate}
                >
                  <i className="fa fa-plus"></i>
                </Button>
              </Col>
            </Row>
          </PermissionsGate>
          <AdjustmentDetailModal
            data={detailData}
            create={createAdjustment}
            setDataUpdatedCnt={setDataUpdatedCnt}
            dataUpdatedCnt={dataUpdatedCnt}
            handleClose={closeAdjustmentModal}
            disableSubmit={disableSubmit}
            show={showAdjustmentDetail}
          />
        </Container>
      </PermissionsGate>
    </>
  );
}

AdjustmentPage.whyDidYouRender = true;

export default AdjustmentPage;
